import { createApp } from "vue"
import videoResult from "./videoResult.vue"

let previousApp = null;
let app = null;
let mountNode = null;

export default function ShowVideoResult(options) {
  // 创建一个节点，并将组件挂载上去
  mountNode = document.createElement("div");
  document.body.appendChild(mountNode);
  app = createApp(videoResult, {
    ...options,
    visible: true,
    remove() {
      app.unmount(mountNode); //创建完后要进行销毁
      mountNode && document.body.removeChild(mountNode);
    },
  });
  previousApp = app;
  return app.mount(mountNode);
}

export function removeVideoResult(options) {
  // 创建一个节点，并将组件挂载上去
  if (previousApp) {
    previousApp.unmount(mountNode); //创建完后要进行销毁
    mountNode && document.body.removeChild(mountNode);
  }
}
import { defineStore } from "pinia";
import message from "@/components/functionCallComponent/message.jsx";
import { showActionDialog } from "@/components/functionCallComponent/action/action.js";
import apiVideo from "@/api/api/apiVideo.js";

import objectStorage, {
  getObjectStorageFullUrl,
} from "@/lib/objectStorage/objectStorage.js";

const creatVideoEditDataStore = defineStore({
  id: 'creatVideoEditDataStore',
  state: () => {
    return {
      imageUploadData: null,
      lyrics: "",
      genre: "christmas vibe",
      vocalGender: "male",
      christmasHat: 2
    }
  },
  actions: {
    clearAllData() {
      this.imageUploadData = null;
      this.lyrics = "";
      this.genre = "christmas vibe";
      this.vocalGender = "male";
      this.christmasHat = 2;

    },
    doUploadImage(imageData, callbackUploadData) {
      return new Promise(async (resolve, reject) => {
        if (!imageData.changed && imageData.pathKey) {
          resolve({
            pathKey: imageData.pathKey,
            fullUrl: getObjectStorageFullUrl(imageData.pathKey),
          });
          return;
        }
        try {
          const objectStorageRes =
            await objectStorage.uploadFileToObjectStorage(
              imageData.file,
              (obj) => {
                callbackUploadData?.(obj);
              }
            );
          resolve(objectStorageRes);
        } catch (e) {
          reject(e);
        }
      });
    },
    saveImageData(imageData) {
      return new Promise(async (resolve, reject) => {
        let closeMsg = message.loading({
          position: "center",
          content: "Uploading...",
        });
        this.doUploadImage(imageData).then((imageUploadData) => {
          this.imageUploadData = imageUploadData;
          closeMsg?.clear();
          resolve(imageUploadData);
        }).catch((e) => {
          message.error({
            position: "top",
            content: "Upload image failed, please try again",
          })
          reject(e);
        });
      })
    },
    setLyrics(lyrics) {
      this.lyrics = lyrics;
    },
    getVideoEditData() {
      return {
        imageUploadData: this.imageUploadData,
        lyrics: this.lyrics,
        genre: this.genre,
        vocalGender: this.vocalGender,
        christmasHat: this.christmasHat
      }
    },
    setVideoEditData(videoEditData) {
      this.imageUploadData = videoEditData?.imageUploadData;
      this.lyrics = videoEditData?.lyrics;
      this.genre = videoEditData?.genre;
      this.vocalGender = videoEditData?.vocalGender;
      this.christmasHat = videoEditData?.christmasHat;
    }
  },

})

export default creatVideoEditDataStore;
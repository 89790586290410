export default {
  default:{
    title: 'Mureka.ai',
    description: 'AI music generator that transforms your lyrics and prompts into fully produced songs, all unlimited and royalty-free!',
  },
  create: {
    title: 'Create',
    description: 'AI music generator that transforms your lyrics and prompts into fully produced songs, all unlimited and royalty-free!',
  },
  home: {
    title: 'Home',
    description: 'As an AI music creator, AI song generator, and AI vocals, Mureka.ai make it simple and fun. From AI song writer to AI singer, designed for both beginners and pros.'
  },
  library: {
    title: 'Library',
    description: 'Songs generated by Mureka.ai will be fully saved in the Mureka library. Share your AI-created songs anytime or publish them to the Mureka community for global music fans.',
  },
  subscribe: {
    title: 'Subscribe',
    description: 'Subscribe to Mureka now and unlock exclusive privileges! Enjoy all the premium AI music creation features, such as downloading MP3s, Stem audios, videos, and uploading audio or YouTube links as references.',
  },
  GenreDetail: {
    title: 'Genre Detail',
    description: 'Songs generated by Mureka support a wide variety of genres, including Pop, Rock, Hip-Hop, Electronic, Disco, Latin, Metal, Punk, R&B, Funk, Indie, Afrobeat, Experimental, World Music, and more. '
  },
  about: {
    title: 'About',
    description: 'Mureka is an AI-powered song generator that transforms your ideas into fully produced songs, empowering you to become a music creator - All completely free and unlimited royalty-free.'
  }
}
import { createI18n } from "vue-i18n";
import GlobalConfig from "@/config/config.js";
import { getDataByFields } from "@/utils/tools.js";

import commonEn from "./en/common.json";
// import commonCn from "./cn/common.json";
import commonJa from "./ja/common.json";
import commonKo from "./ko/common.json";
import commonSp from "./sp/common.json";
import commonPo from "./po/common.json";
import commonZHCN from "./zh-CN/common.json";
import commonZHTW from "./zh-TW/common.json";

import storeEn from "./en/store.json";
// import storeCn from "./cn/store.json";
import storeJa from "./ja/store.json";
import storeKo from "./ko/store.json";
import storeSp from "./sp/store.json";
import storePo from "./po/store.json";
import storeZHCN from "./zh-CN/store.json";
import storeZHTW from "./zh-TW/store.json";

import createEn from "./en/create.json";
// import createCn from "./cn/create.json";
import createJa from "./ja/create.json";
import createKo from "./ko/create.json";
import createSp from "./sp/create.json";
import createPo from "./po/create.json";
import createZHCN from "./zh-CN/create.json";
import createZHTW from "./zh-TW/create.json";

import creditEn from "./en/credit.json";
// import creditCn from "./cn/credit.json";
import creditJa from "./ja/credit.json";
import creditKo from "./ko/credit.json";
import creditSp from "./sp/credit.json";
import creditPo from "./po/credit.json";
import creditZHCN from "./zh-CN/credit.json";
import creditZHTW from "./zh-TW/credit.json";

// import libraryCn from "./cn/library.json";
import libraryEn from "./en/library.json";
import libraryJa from "./ja/library.json";
import libraryKo from "./ko/library.json";
import librarySp from "./sp/library.json";
import libraryPo from "./po/library.json";
import libraryZHCN from "./zh-CN/library.json";
import libraryZHTW from "./zh-TW/library.json";

// import mineCn from "./cn/mine.json";
import mineEn from "./en/mine.json";
import mineJa from "./ja/mine.json";
import mineKo from "./ko/mine.json";
import mineSp from "./sp/mine.json";
import minePo from "./po/mine.json";
import mineZHCN from "./zh-CN/mine.json";
import mineZHTW from "./zh-TW/mine.json";

// import landCn from "./cn/land.json";
import landEn from "./en/land.json";
import landJa from "./ja/land.json";
import landKo from "./ko/land.json";
import landSp from "./sp/land.json";
import landPo from "./po/land.json";
import landZHCN from "./zh-CN/land.json";
import landZHTW from "./zh-TW/land.json";

// import loginCn from "./cn/login.json";
import loginEn from "./en/login.json";
import loginJa from "./ja/login.json";
import loginKo from "./ko/login.json";
import loginSp from "./sp/login.json";
import loginPo from "./po/login.json";
import loginZHCN from "./zh-CN/login.json";

import paymentEn from "./en/payment.json";
import paymentJa from "./ja/payment.json";
import paymentKo from "./ko/payment.json";
import paymentSp from "./sp/payment.json";
import paymentPo from "./po/payment.json";
import paymentZHCN from "./zh-CN/payment.json";
import paymentZHTW from "./zh-TW/payment.json";


const messages = {
  // cn: {
  //   // 中文
  //   common: commonCn,
  //   store: storeCn,
  //   create: createCn,
  //   credit: creditCn,
  //   mine: mineCn,
  //   library: libraryCn,
  //   land: landCn,
  //   login: loginCn
  // },
  en: {
    common: commonEn,
    store: storeEn,
    create: createEn,
    credit: creditEn,
    mine: mineEn,
    library: libraryEn,
    land: landEn,
    login: loginEn,
    payment: paymentEn,
  },
  ja: {
    common: commonJa,
    store: storeJa,
    create: createJa,
    credit: creditJa,
    mine: mineJa,
    library: libraryJa,
    land: landJa,
    login: loginJa,
    payment: paymentJa,
  },
  ko: {
    // 韩语
    common: commonKo,
    store: storeKo,
    create: createKo,
    credit: creditKo,
    mine: mineKo,
    library: libraryKo,
    land: landKo,
    login: loginKo,
    payment: paymentKo,
  },
  es: {
    // 西班牙语
    common: commonSp,
    store: storeSp,
    create: createSp,
    credit: creditSp,
    mine: mineSp,
    library: librarySp,
    land: landSp,
    login: loginSp,
    payment: paymentSp,
  },
  pt: {
    // 葡萄牙语
    common: commonPo,
    store: storePo,
    create: createPo,
    credit: creditPo,
    mine: minePo,
    library: libraryPo,
    land: landPo,
    login: loginPo,
    payment: paymentPo,
  },
  "zh-CN": {
    common: commonZHCN,
    store: storeZHCN,
    create: createZHCN,
    credit: creditZHCN,
    mine: mineZHCN,
    library: libraryZHTW,
    land: landZHCN,
    login: loginZHCN,
    payment: paymentZHCN,
  },
  "zh-TW": {
    common: commonZHTW,
    store: storeZHTW,
    create: createZHTW,
    credit: creditZHTW,
    mine: mineZHTW,
    library: libraryZHTW,
    land: landZHTW,
    login: loginZHCN,
    payment: paymentZHTW,
  }
};

export const mapBrowserLanguage = (appLanguage) => {
  // 获取浏览器的首选语言
  const browserLanguage = appLanguage || navigator.language || navigator.userLanguage;
  if (!GlobalConfig.switchLanguage) return "en";

  // 定义语言映射规则
  const languageMap = {
    en: ["en", "en-US", "en-GB", "en-AU", "en-CA"], // 英语
    'zh-CN': ["zh", "zh-CN", "zh-Hans", "zh-Hant"],// 中文简体
    'zh-TW': [, "zh-HK", "zh-MO", "zh-SG", "zh-TW"], // 中文繁体
    ja: ["ja", "ja-JP"],                            // 日语
    ko: ["ko", "ko-KR"],                            // 韩语
    es: ["es", "es-ES", "es-MX", "es-419"],         // 西班牙语
    pt: ["pt", "pt-BR", "pt-PT"],                   // 葡萄牙语
  };

  // 遍历映射规则，找到最靠近的语言
  for (const [key, values] of Object.entries(languageMap)) {
    if (values.includes(browserLanguage)) {
      return key; // 返回映射的语言代码
    }
  }

  // 如果没有匹配到，返回默认值 (如 "en")
  return "en";
}

const localData =
  localStorage.getItem(GlobalConfig.localStorageGlobalKey) || "{}";
const { appLanguage } = JSON.parse(localData);

const language = import.meta.env.VITE_LANGUAGE;
console.log("VITE_LANGUAGE", language);
const i18n = createI18n({
  locale: mapBrowserLanguage(appLanguage), // language ?? "en",
  //appLanguage ?? "en", // 默认语言
  fallbackLocale: "en", // 如果找不到当前语言的翻译，默认回退到的语言
  messages,
});

export const $$t = (key, language) => {
  if (language && Object.keys(messages).includes(language)) {
    return getDataByFields(messages[language], key);
  } else {
    return i18n.global.t(key);
  }
};

export const $$tValue = (key, params) => {  // 替换‘text{key}’ ,{key:value}格式
  return i18n.global.t(key, params);
}

export const $$language = (language) => {
  if (language && Object.keys(messages).includes(language)) {
    if (i18n.global.locale != language) {
      let old = i18n.global.locale;
      i18n.global.locale = language;
      setTimeout(() => {
        triggleBrodcastEvent("languageChange", language, old);
      }, 1);
    }
  }
  return i18n.global.locale;
};

const languageChangeListeners = [];
const triggleBrodcastEvent = (type, language) => {
  languageChangeListeners.forEach((listener) => {
    listener(type, language);
  });
};

export const $$registLanguageChangeListener = (listener) => {
  languageChangeListeners.push(listener);
};

export const $$removeLanguageChangeListener = (listener) => {
  const index = languageChangeListeners.indexOf(listener);
  if (index > -1) {
    languageChangeListeners.splice(index, 1);
  }
};
export const LanguageList = [
  {
    key: "en",
    label: "English",
  },
  {
    key: "zh-CN",
    label: "中文（简体）",
  },
  {
    key: "zh-TW",
    label: "中文（繁體）",
  },
  {
    key: "ja",
    label: "日本語",
  },
  {
    key: "ko",
    label: "일본어",
  },
  {
    key: "es",
    label: "Español",
  },
  {
    key: "pt",
    label: "Português",
  },

]

export const LanguageListMap = {
  // cn: [
  //   {
  //     key: "cn",
  //     label: "中文",
  //   },
  //   {
  //     key: "en",
  //     label: "英语",
  //   },
  //   {
  //     key: "ja",
  //     label: "日语",
  //   },
  //   {
  //     key: "ko",
  //     label: "韩语",
  //   },
  //   {
  //     key: "es",
  //     label: "西班牙语",
  //   },
  //   {
  //     key: "pt",
  //     label: "葡萄牙语",
  //   },
  // ],
  en: [
    // {
    //   key: "cn",
    //   label: "Chinese",
    // },
    {
      key: "en",
      label: "English",
    },
    {
      key: "ja",
      label: "Japanese",
    },
    {
      key: "ko",
      label: "Korean",
    },
    {
      key: "es",
      label: "Spanish",
    },
    {
      key: "pt",
      label: "Portuguese",
    },
  ],
  ja: [
    // {
    //   key: "cn",
    //   label: "中国語",
    // },
    {
      key: "en",
      label: "英語",
    },

    {
      key: "ja",
      label: "日本語",
    },
    {
      key: "ko",
      label: "韓国語",
    },
    {
      key: "es",
      label: "スペイン語",
    },
    {
      key: "pt",
      label: "ポルトガル語",
    },
  ],
  ko: [
    // {
    //   key: "cn",
    //   label: "중국어",
    // },
    {
      key: "en",
      label: "영어",
    },
    {
      key: "ja",
      label: "일본어",
    },
    {
      key: "ko",
      label: "한국어",
    },
    {
      key: "es",
      label: "스페인어",
    },
    {
      key: "pt",
      label: "포르투갈어",
    },
  ],
  es: [
    // {
    //   key: "cn",
    //   label: "Chino.",
    // },
    {
      key: "en",
      label: "Inglés",
    },
    {
      key: "ja",
      label: "japonés",
    },
    {
      key: "ko",
      label: "Coreano",
    },

    {
      key: "es",
      label: "Español",
    },
    {
      key: "pt",
      label: "Portugués",
    },
  ],
  pt: [
    // {
    //   key: "cn",
    //   label: "Chinês",
    // },
    {
      key: "en",
      label: "Inglês",
    },
    {
      key: "ja",
      label: "Japonês",
    },
    {
      key: "ko",
      label: "Coreano",
    },
    {
      key: "es",
      label: "Espanhol",
    },
    {
      key: "pt",
      label: "Português",
    },
  ],
};

export default i18n;

import { defineStore, setActivePinia } from "pinia";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";
import layoutConfig from "@/config/layoutConfig";
import globleConfig from "@/config/config.js";
import message from "@/components/functionCallComponent/message.jsx";
import apiCommon from "@/api/api/apiCommon.js";


const CreateModeClickedStateMap = {
  easy: "click_basic",
  hard: "click_advanced",
  audioRedDot: "show_audio_red_dot",
  "home_vocal": "home_vocal"
}

const useGlobalUserStateStore = defineStore({
  id: "globalUserStateStore",
  state: () => {
    // let datasC = {};
    // try {

    //   datasC = JSON.parse(window.localStorage.getItem(
    //     `${globleConfig.appname}_createUserStateData`
    //   ) ?? "{}");
    // } catch (e) {
    //   datasC = {};
    // }

    // const {
    //   createModeClickedStateVisible,
    //   hasReportedCreateModeClickedState,
    // } = datasC;

    return {
      createModeClickedStateVisible: false,
      hasReportedCreateModeClickedState: false,
      createModeClickedState: {
        easyMode: false,
        hardMode: false,
      }
    };
  },
  actions: {
    cacheCreateModeClickedState() {
      window.localStorage.setItem(`${globleConfig.appname}_createUserStateData`, {
        createModeClickedStateVisible: this.createModeClickedStateVisible,
        hasReportedCreateModeClickedState: this.hasReportedCreateModeClickedState,
      })
    },
    showBasicModeClickedGuidVisible(type) {
      if (!this.hasReportedCreateModeClickedState) {
        if (type == "easy") {
          if (!this.createModeClickedState.easyMode) {
            this.createModeClickedStateVisible = true;
          } else {
            this.createModeClickedStateVisible = false;
          }
        } else {
          if (!this.createModeClickedState.hardMode) {
            this.createModeClickedStateVisible = true;
          } else {
            this.createModeClickedStateVisible = false;
          }
        }

        //this.cacheCreateModeClickedState();
      }
    },
    queryUserState() {
      return new Promise((resolve, reject) => {
        apiCommon.queryCreateModeState().then((res) => {
          if (res.code == 200) {
            this.createModeClickedState = {
              easyMode: res.data.is_click_basic == true,
              hardMode: res.data.is_click_advanced == true,
            }
            this.hasReportedCreateModeClickedState = res.data?.is_click_basic == true && res.data?.is_click_advanced == true;
            // this.cacheCreateModeClickedState();
            resolve(res.data)

          } else {
            reject(res)
          }
        }).catch((err) => {
          reject(err)
          console.log(err);
        })
      })
    },

    reportCreateModeClickedState(type) {
      const userDataStore = useUserDataStore();
      switch (type) {
        case "easy":
          this.createModeClickedStateVisible = false;
          break;
        case "hard":
          this.createModeClickedStateVisible = false
          break;
        default:
          break;
      }
      return new Promise((resolve, reject) => {
        if (!userDataStore.isLogin) {
          reject("用户未登录")
          return
        }
        if (this.hasReportedCreateModeClickedState) {
          reject("已经上报过")
          return
        }
        apiCommon.reportUserState({
          type: CreateModeClickedStateMap[type]
        }).then((res) => {
          if (res.code == 200) {
           
            switch (type) {
              case "easy":
                this.createModeClickedState.easyMode = true;
                break;
              case "hard":
                this.createModeClickedState.hardMode = true
                break;
              default:
                break;
            }
            // this.cacheCreateModeClickedState();
            this.hasReportedCreateModeClickedState = res.data?.is_click_basic == true && res.data?.is_click_advanced == true;
            resolve(res.data)
          } else {
            reject(res)
          }
        }).catch((err) => {
          reject(err)
          console.log(err);
        })
      })
    }
  }
});

export default useGlobalUserStateStore;

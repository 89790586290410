import { Midi } from "@tonejs/midi";
// import * as Tone from "tone";
// import lamejs from "lamejs";

export const parseMidiFile = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const midi = new Midi(arrayBuffer);
  console.log("MIDI 内容:", midi);
  return midi;
};
export async function getMidiFileDuration(file) {
  try {
    // 将 MIDI 文件读取为 ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();

    // 使用 @tonejs/midi 解析文件
    const midi = new Midi(arrayBuffer);

    // 获取时长（单位：秒）
    const duration = midi.duration;

    console.log(`MIDI 文件时长: ${duration} 秒`);
    return duration;
  } catch (error) {
    console.error("解析 MIDI 文件时发生错误:", error);
    throw error;
  }
}


// export const isMidiFile = (file) => {
//   return file.type === "audio/midi" || file.name.endsWith(".midi") || file.name.endsWith(".mid");
// };

// export const isMidiUrl = (url) => {
//   return /(.mid|.midi)$/i.test(url ?? '');

// }

// async function midiToPCM_Old(midiFile, config = {}) {
//   try {
//     // 默认渲染配置
//     const defaultConfig = {
//       batchSize: 100,
//       minNoteDuration: 0.1,
//       maxPolyphony: 10,
//     };
//     const renderConfig = { ...defaultConfig, ...config };

//     // 1. 解析 MIDI 文件
//     const midiData = midiFile instanceof File ? await midiFile.arrayBuffer() : midiFile;
//     console.log("🍎🍎🍎🍎MIDI 数据:", midiData);

//     const midi = new Midi(midiData);
//     const channels = midi.tracks.length;
//     let timer = null;
//     // 2. 使用 Tone.js 离线渲染 MIDI 数据
//     let audioBuffer = null

//     audioBuffer = await Tone.Offline(async (context) => {
//       // 设置渲染参数
//       const synth = new Tone.PolySynth(Tone.Synth, {
//         maxPolyphony: renderConfig.maxPolyphony,
//       }).toDestination();

//       for (let track of midi.tracks) {
//         console.log(`Rendering track: ${track.name || "Unnamed"}`);
//         for (let i = 0; i < track.notes.length; i += 1) {
//           const note = track.notes[i];
//           if (note.duration > renderConfig.minNoteDuration) {
//             console.log(`🍎🍎🍎🍎🍎🍎Playing note: ${note.name} at time: ${note.time} in duration: ${note.duration}`);
//             synth.triggerAttackRelease(note.name, note.duration, note.time);
//             // 小间隔，避免长时间阻塞
//             await new Promise(resolve => setTimeout(resolve, 0));
//           }
//           if (i == track.notes.length - 1) {
//             console.log("🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎", context);
//             timer = setTimeout(async () => {
//               if (audioBuffer == null) {
//                 // context.transport.stop(); // 停止播放
//                 // context.dispose(); // 释放资源
//                 console.log("渲染超时，提前终止");
//                 throw new Error("渲染超时，提前终止");
//               } else {
//                 console.log("渲染超时，提前终止");
//                 throw new Error("渲染超时，提前终止");
//               }
//             }, 15 * 1000)
//           }
//         }
//       }
//     }, midi.duration, channels, 44100);

//     clearTimeout(timer);
//     // 3. 提取 PCM 数据
//     const pcmData = [];
//     for (let i = 0; i < channels; i++) {
//       pcmData.push(audioBuffer.getChannelData(i).slice());
//     }

//     return {
//       pcmData,
//       channels,
//       sampleRate: audioBuffer.sampleRate,
//       duration: audioBuffer.duration,
//     };
//   } catch (error) {
//     clearTimeout(timer);
//     console.error("MIDI 转 PCM 失败：", error.message);
//     throw new Error(`MIDI 转 PCM 失败: ${error.message}`);
//   }
// }
// const testTracks = [
//   {
//     notes: [
//       {
//         time: 0,
//         name: 'a',
//         duration: 0.1
//       }, {
//         time: 0,
//         name: 'a2',
//         duration: 0.2
//       }, {
//         time: 0.2,
//         name: 'b3',
//         duration: 0.1
//       }, {
//         time: 0.21,
//         name: 'b4',
//         duration: 0.2
//       }, {
//         time: 0.41,
//         name: 'c4',
//         duration: 0.1
//       }
//     ]
//   }
// ]
// const reGroupMidiTrackData = (tracks) => {
//   const result = [];
//   for (let track of tracks) {
//     track.notes.sort((a, b) => a.time > b.time ? 1 : -1);
//     const newTracksNotes = track.notes.reduce((res, note, index) => {
//       if (index == 0) {
//         res.push({
//           ...note,
//           duration: note.duration,
//           name: note.name,
//           time: note.time,
//           nameArray: [note.name],
//           durationArray: [note.duration],
//           timeArray: [note.time],
//           startTime: note.time,
//         })

//       } else {
//         if (note.time < res[res.length - 1].time + res[res.length - 1].duration) {
//           res[res.length - 1].nameArray.push(note.name);
//           res[res.length - 1].durationArray.push(note.duration);
//           res[res.length - 1].timeArray.push(note.time);
//           res[res.length - 1].duration = Math.max(...res[res.length - 1].durationArray);
//         } else {
//           res.push({
//             ...note,
//             duration: note.duration,
//             name: note.name,
//             time: note.time,
//             nameArray: [note.name],
//             durationArray: [note.duration],
//             timeArray: [note.time],
//             startTime: note.time,
//           })
//         }
//       }
//       return res;
//     }, [])
//     result.push({
//       ...track,
//       notes: newTracksNotes
//     });
//   }
//   return result;

// }


// async function midiToPCM(midiFile, config = {}) {
//   try {
//     // 1. 解析 MIDI 文件
//     const midiData = midiFile instanceof File ? await midiFile.arrayBuffer() : midiFile;

//     const midi = new Midi(midiData);
//     const channels = midi.tracks.length;
//     // 2. 使用 Tone.js 离线渲染 MIDI 数据
//     let audioBuffer = await Tone.Offline(async (context) => {
//       // 设置渲染参数
//       const synth = new Tone.Synth().toDestination();
//       for (let track of midi.tracks) {
//         new Tone.Part((time, event) => {
//           synth.triggerAttackRelease(
//             event.name,
//             event.duration,
//             time,
//             event.velocity
//           );
//         }, track.notes).start();
//         context.transport.start();
//       }
//     }, midi.duration, channels, 44100);
//     // 3. 提取 PCM 数据
//     const pcmData = [];
//     for (let i = 0; i < channels; i++) {
//       pcmData.push(audioBuffer.getChannelData(i).slice());
//     }
//     return {
//       pcmData,
//       channels,
//       sampleRate: audioBuffer.sampleRate,
//       duration: audioBuffer.duration,
//     };
//   } catch (error) {
//     console.error("MIDI 转 PCM 失败：", error.message);
//     throw new Error(`MIDI 转 PCM 失败: ${error.message}`);
//   }
// }

// export function convertPCMToMP3(pcmData, sampleRate = 44100, channels = 2, bitrate = 128) {
//   return new Promise((resolve, reject) => {
//     try {
//       // 初始化 MP3 编码器
//       const mp3Encoder = new lamejs.Mp3Encoder(channels, sampleRate, bitrate);

//       // 创建一个新的 MP3 编码数据
//       const mp3Data = [];

//       // 合并所有通道的 PCM 数据
//       const interleavedPCMData = new Float32Array(pcmData[0].length * channels);
//       // 
//       let offset = 0;
//       for (let i = 0; i < pcmData[0].length; i++) {
//         for (let channel = 0; channel < channels; channel++) {
//           interleavedPCMData[offset++] = pcmData[channel][i];
//         }
//       }

//       // 将 PCM 数据转成 16-bit 格式
//       const pcmData16Bit = new Int16Array(interleavedPCMData.length);
//       for (let i = 0; i < interleavedPCMData.length; i++) {
//         pcmData16Bit[i] = Math.max(-32768, Math.min(32767, interleavedPCMData[i] * 32767)); // 防止溢出
//       }

//       // 编码 PCM 数据
//       const mp3Buffer = mp3Encoder.encodeBuffer(pcmData16Bit);
//       if (mp3Buffer.length > 0) {
//         mp3Data.push(new Int8Array(mp3Buffer));
//       }

//       // 完成 MP3 编码并生成文件
//       const finalBuffer = mp3Encoder.flush();
//       if (finalBuffer.length > 0) {
//         mp3Data.push(new Int8Array(finalBuffer));
//       }

//       // 将 MP3 数据转为 Blob 对象
//       const blob = new Blob(mp3Data, { type: 'audio/mp3' });
//       resolve({
//         blob,
//         file: new File([blob], 'output.mp3', { type: 'audio/mp3' }),
//         blobUrl: URL.createObjectURL(blob),
//       });
//     } catch (error) {

//       reject(error);
//     }
//   });
// }

// export const convertMidiFileToMp3 = (midiFile) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const midiToPCMData = await midiToPCM(midiFile);
//       const mp3Result = await convertPCMToMP3(midiToPCMData.pcmData, midiToPCMData.sampleRate, midiToPCMData.channels);
//       resolve(mp3Result);
//     } catch (error) {
//       reject(error);
//     }
//   })
// }

// // MIDI 裁剪函数
// async function cropMidi(inputMidi, startTime, endTime) {
//   // 解析 MIDI 数据
//   const midiData = inputMidi instanceof File ? await inputMidi.arrayBuffer() : inputMidi;
//   const midi = new Midi(midiData);

//   // 裁剪每个音轨的音符
//   midi.tracks.forEach((track) => {
//     track.notes = track.notes.filter((note) => {
//       // 只保留在裁剪时间段内的音符
//       return note.time >= startTime && note.time <= endTime;
//     });
//   });

//   // 更新文件总时长
//   midi.header.ppq = midi.header.ppq;
//   midi.header.tempos.forEach((tempo) => {
//     if (tempo.time > endTime) tempo.time = endTime;
//   });

//   // 导出新的 MIDI 文件
//   const midiClipedData = midi.toArray();
//   const croppedMidi = new Blob([midiClipedData], { type: "audio/midi" });
//   const blobUrl = URL.createObjectURL(croppedMidi);
//   const midiFile = new File([midiClipedData], inputMidi.name, { type: 'audio/midi' });

//   return {
//     blob: croppedMidi,
//     file: midiFile,
//     blobUrl,
//   }
// }

// async function getMidiNotes(midiFile) {
//   // 解析 MIDI 文件
//   const midiData = midiFile instanceof File ? await midiFile.arrayBuffer() : midiFile;
//   const midi = new Midi(midiData);

//   // 获取所有音轨的音符数据
//   const notesData = midi.tracks.map((track, trackIndex) => {
//     return {
//       trackIndex,
//       instrument: track.instrument.name,
//       notes: track.notes.map((note) => ({
//         name: note.name,          // 音符名称，例如 "C4"
//         time: note.time,          // 音符开始时间（单位：秒）
//         duration: note.duration,  // 音符持续时间（单位：秒）
//         velocity: note.velocity,  // 音符力度（0.0 到 1.0）
//       })),
//     };
//   });

//   return notesData;
// }


export default {
  parseMidiFile,
  getMidiFileDuration,
  // isMidiFile,
  // isMidiUrl,
  // midiToPCM,
  // convertPCMToMP3,
  // convertMidiFileToMp3,
  // cropMidi,
}



<template>
  <div class="login-input-component" :class="props.theme">
    <el-input
      :showPassword="props.showPassword"
      @input="input"
      @change="change"
      @keyup.enter="onEnterPressed"
      v-model="inputValue"
      :maxlength="props.max"
      :minlength="props.min"
      :placeholder="props.placeholder"
      :clearable="props.clearable"
      :disabled="props.disabled"
    >
      <template #prefix v-if="props.prefix">
        <div class="icon-prefix">
          <img :src="props.prefix" alt="" />
        </div>
      </template>
    </el-input>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from "vue"
const inputValue = ref("")
const props = defineProps({
  clearable: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: [String, Boolean, Object],
    default: false,
  },
  max: {
    type: Number,
    default: undefined,
  },
  min: {
    type: Number,
    default: undefined,
  },
  theme: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  showPassword: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    require: true,
  },
  disabled:{
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(["change", "enterPressed", "input"])
const change = (val) => {
  inputValue.value = val
  emit("change", val)
}
const input = (val, ...args) => {
  if (props.max > 0) {
    inputValue.value = val.slice(0, props.max)
  }
  emit("input", inputValue.value, ...args)
}

const onEnterPressed = (event) => {
  if (event.keyCode == 13) {
    emit("enterPressed")
  }
}

watch(
  () => {
    return props.value
  },
  () => {
    inputValue.value = props.value
  }
)
onMounted(() => {
  inputValue.value = props.value
})
onMounted(() => {})
</script>
<style lang="scss" scoped>
.login-input-component {
  :deep(.el-input__wrapper) {
    background-color: rgba(255, 255, 255, 0) !important;
    box-shadow: unset !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-radius: 8px;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
    }
    .icon-prefix {
      padding-left: 0px;
      padding-right: 2px;
      img {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
    .el-input__inner {
      color: #fff;
    }
    .el-input__inner::placeholder {
      color: rgba(255, 255, 255, 0.3) !important;
    }
    .el-input__validateIcon {
      display: none;
    }
    .el-input__inner {
      color: #fff;
    }
    &:hover {
      color: #fff;
    }
    &.is-focus {
      color: #fff;
      box-shadow: 0 0 0 1px #94ADFF inset !important;
    }
    &.is-disabled {
      color: #fff;
      cursor: not-allowed;
      background-color: rgba(255, 255, 255, 0) !important;
      .el-input__inner {
        color: #fff;
      }
    }
  }
}
.login-input-component {
  width: 100%;
  height: 48px;

  .el-input {
    height: 100%;
    width: 100%;
  }
  .el-input__password {
    background: url(../../../assets/img/ic_ice.png);
    background-size: 100% 100%;
    height: 24px;
    width: 24px;
    svg {
      display: none;
    }
  }
}
.login-input-component.form {
  .el-input__wrapper {
    height: 48px;
    border-radius: 8px 8px 8px 8px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>

<template>
  <div class="wrapper">
    <template v-if="rightType == 'generate'">
      <div class="title">
        {{ $$t("mine.beinggenerated") }}<br />
        {{ $$t("mine.Logintodownload") }}
      </div>
      <!-- <div class="right-content">
        <div :key="item" v-for="item in [0]" class="line-item">
          <img src="@/assets/img/login/spin1.svg" class="spin" />{{ $$t("mine.generating") }}
        </div>
      </div> -->
    </template>
    <template v-else-if="rightType == 'video'">
      <div class="title">{{ "Sign in to generate" }}<br />{{ "a lip-sync music video for Free!" }}</div>
    </template>
    <template v-else-if="rightType == 'invite'">
      <div class="title">{{ $$t("credit.invite_sign_in") }}<br />{{ $$t("credit.create_songs_for_free") }}</div>
    </template>
    <template v-else>
      <div class="title">{{ $$t("mine.songsfor") }}<br />{{ $$t("mine.everyday") }}</div>
    </template>
  </div>
</template>

<script setup>
import { $$t, $$language } from "@/i18n/i18n.js"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"

// rightType: "generate" | "login",
const props = defineProps({
  rightType: {
    type: String,
    default: "generate",
  },
  create_type: {
    type: String,
    default: "2",
  },
  source: {
    type: String,
    default: "other",
  },
})

onMounted(() => {
  //  "login"
  firebaseUtils.logViewEvent(props.rightType === "generate" ? "newuser_login" : "activity_register_page", {
    create_type: props.create_type,
    page_route: window.location.pathname,
    source: props.source,
    activity_name: props.rightType !== "generate" && "christmas_lip" || ""
  })
})
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 772px;
  gap: 36px;
  .title {
    font-size: 36px;
    font-weight: 500;
    line-height: 52px;
    text-align: center;
  }
  .right-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    .line-item {
      display: flex;
      gap: 12px;
      height: 88px;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      // background: linear-gradient(
      //   102deg,
      //   rgba(118, 255, 232, 0.2) 23%,
      //   rgba(128, 251, 255, 0.2) 89%
      // );
      background-color: rgba(148, 173, 255, 0.2);
      font-size: 20px;
      font-weight: 500;
      line-height: 52px;
      color: #94adff;

      .spin {
        width: 24px;
        height: 24px;
        animation: spin 1.8s linear infinite;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 340px;
    gap: 24px;
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
    .right-content {
      gap: 8px;
      .line-item {
        height: 52px;
        background: rgba(148, 173, 255, 0.2);
        font-size: 16px;
        font-weight: 500;
        .spin {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>

import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import { $$t } from "@/i18n/i18n.js";

import ShowActionDialog from "@/components/functionCallComponent/action/action.js";
import useCreateEasyModeStore from "@/store/create/createEasyModeStore.js";
import useFeedListStore from "./feedListDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
import useMuseDataStore from "./museDataStore.js";
import useCreatVideoEditDataStore from "@/store/createVideo/creatVideoEditDataStore.js"
import useCreatVideoResultDataStore from "@/store/createVideo/creatVideoResultDataStore.js"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js"
import useUserDataStore from "@/store/userDataStore"
import userReferMusicStore from "./referMusicStore.js";
import userVocalMusicStore from "./vocalMusicStore.js";
import userMotifDataStore from "./motifDataStore.js";
import usePromptDataStore from "./promptDataStore.js";

// import useDraftDataStore from './draftDataStore.js';
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();

const useDraftDataStore = defineStore({
  id: "draftDataStore",
  state: () => {
    return {
      draftData: {},
      feed_id: "",
      lastGenerateDraftData: null,
      createAdvancedModeAllData: {},
    };
  },
  actions: {
    async tempCacheAdvanceData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.createAdvancedModeAllData = {
        lyricData: lyricDataStore.getLyricData(),
        museData: museDataStore.getMuseData(),
      }
      console.log(this.createAdvancedModeAllData)
    },
    restoreCacheAdvanceData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      lyricDataStore.setLyricData(this.createAdvancedModeAllData?.lyricData ?? {});
      museDataStore.setMuseData(this.createAdvancedModeAllData?.museData ?? {});
    },

    cacheSimilarAdvanceData(advanceModeSimilarDialogVisible) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createAdvancedModeAllData = {
        advanceModeSimilarDialogVisible: advanceModeSimilarDialogVisible,
        lyricData: lyricDataStore.getLyricData(),
        museData: museDataStore.getMuseData(),
      }
      localStorage.setItem(`${globalConfig.appname}_draftData_similar_advance_data`, JSON.stringify(createAdvancedModeAllData));

      localStorage.setItem(`${globalConfig.appname}_draftData_advance_data`, JSON.stringify(this.createAdvancedModeAllData));

    },
    restoreSimilarCacheAdvanceData(isResetProRefer) {
      return new Promise((resolve, reject) => {
        const lyricDataStore = useLyricDataStore();
        const museDataStore = useMuseDataStore();
        const userDataStore = useUserDataStore();

        let createAdvancedModeSimilarAllData = null;
        let createAdvancedModeAllData = {};

        try {
          createAdvancedModeSimilarAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_similar_advance_data`));
          createAdvancedModeAllData = JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData_advance_data`));
          if (isResetProRefer && userDataStore.userInfo?.user?.vip_level_name == 'pro') {
            Object.values(createAdvancedModeSimilarAllData.museData).forEach((item) => {
              if (item?.is_subscription_privilege) {
                item.is_subscription_privilege = false;
              }
            })
          }
        }
        catch (e) {
        }
        if (createAdvancedModeSimilarAllData) {
          lyricDataStore.setLyricData(createAdvancedModeSimilarAllData.lyricData);
          museDataStore.setMuseData(createAdvancedModeSimilarAllData.museData);
        }
        if (createAdvancedModeAllData) {
          this.createAdvancedModeAllData = createAdvancedModeAllData;
        }
        resolve(createAdvancedModeSimilarAllData.advanceModeSimilarDialogVisible)
      })

    },
    upDateSimilarCacheAdvanceData() {
      const museDataStore = useMuseDataStore();
      const museData = museDataStore.getMuseData();
      if (userDataStore.userInfo?.user?.vip_level_name == 'pro') {
        Object.values(museData).forEach((item) => {
          if (item?.is_subscription_privilege) {
            item.is_subscription_privilege = false;
          }
        })
      }
      museDataStore.setMuseData(museData);
    },
    setAdvancedModeAllData(data) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      lyricDataStore.initLyricData(data);
      museDataStore.initMuseData(data);
    },
    setDraftData(data, isReset = false) {
      // mode: 0-默认 1-ez 
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.draftData = data ?? {};
      this.feed_id = data?.feed_id;
      const createEasyModeStore = useCreateEasyModeStore();
      if (data?.mode == 1) {
        createEasyModeStore.initEasyPromptData(data, isReset);
      } else {
        lyricDataStore.initLyricData(data, isReset);
        museDataStore.initMuseData(data);
      }
    },

    geLocalDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createEasyModeStore = useCreateEasyModeStore();
      return {
        museData: museDataStore.getMuseData(),
        lyricData: lyricDataStore.getLyricData(),
        easyModeData: createEasyModeStore.getEasyModeData(),
      }

    },
    setLocalDraftData(data) {
      const createEasyModeStore = useCreateEasyModeStore();
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      lyricDataStore.setLyricData(data.lyricData);
      museDataStore.setMuseData(data.museData);
      createEasyModeStore.setEasyModeData(data.easyModeData);
    },

    cacheLocalDraftData() {
      // localStorage.setItem(
      //   `${globalConfig.appname}_draftData`,
      //   JSON.stringify(this.geLocalDraftData())
      // )
    },
    getLocalCacheDraftData() {
      try {
        return JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData`));
      } catch (e) {
        return null;
      }
    },
    getDraftData(params = {}) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.closeActionDialogCallback && this.closeActionDialogCallback();
      this.closeActionDialogCallback = null;
      return new Promise((resolve, reject) => {
        feedListApi
          .getDraftData(params)
          .then((res) => {
            const { code, data, msg } = res;
            if (code == 200) {
              this.draftData = data ?? {};
              this.feed_id = data?.feed_id;
              lyricDataStore.initLyricData(data);
              museDataStore.initMuseData(data);
              useFeedListStore().init({});
              resolve();
            } else {
              if (code == 401) {
                reject("Authentication failed");
                return;
              }

              this.closeActionDialogCallback = ShowActionDialog({
                content: $$t("create.draft_failed"),
                width: "500px",
                confirmLabel: $$t("create.retry"),
                hideCancel: true,
                showCloseBtn: true,
                close: (closeAction) => {
                  this.closeActionDialogCallback();
                  this.closeActionDialogCallback = null;
                },
                confirm: async (closeAction) => {
                  this.closeActionDialogCallback();
                  this.closeActionDialogCallback = null;
                  try {
                    await this.getDraftData(params);
                    resolve();
                  } catch (e) {
                    reject('get draft data failed');
                  }
                },
              });
            }
          })
          .catch((e) => {
            this.closeActionDialogCallback = ShowActionDialog({
              content: $$t("create.draft_failed"),
              width: "500px",
              confirmLabel: $$t("create.retry"),
              hideCancel: true,
              showCloseBtn: true,
              close: (closeAction) => {
                this.closeActionDialogCallback();
                this.closeActionDialogCallback = null;
              },
              confirm: async (closeAction) => {
                this.closeActionDialogCallback();
                this.closeActionDialogCallback = null;
                this.getDraftData(params);
              },
            });
            reject('get draft data failed');
          });
      });
    },
    resetDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createEasyModeStore = useCreateEasyModeStore();
      lyricDataStore.initLyricData({});
      museDataStore.initMuseData({});
      createEasyModeStore.initEasyPromptData({});
    },
    clearAll() {
      const creatVideoEditDataStore = useCreatVideoEditDataStore();
      const createLayoutStore = useCreateLayoutStore();
      const museDataStore = useMuseDataStore();
      this.resetDraftData(true);
      creatVideoEditDataStore.clearAllData();
      createLayoutStore.setActivityConfigTab('create')
      museDataStore.clearAllReferSelectData();
    },
    updateFeedId(newDraftId) {
      this.feed_id = newDraftId;
    },
    setLastGenerateDraftData(feedData) {
      this.lastGenerateDraftData = feedData;
    },
    getLocalDraftData() { },
    clearData() {
      useFeedListStore().clearData();
      useCreatVideoResultDataStore().clearData();
      this.clearAll();
    },
    async getGenerateDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const museData = museDataStore.getMuseGenerateData();
      return {
        // feed_id: this.feed_id,
        mode: 0,
        ...museData,
        ...lyricDataStore.getLyricData(),
      };
    },
    async getGenerateDraftDataEasyMode() {
      const createEasyModeStore = useCreateEasyModeStore();
      return {
        mode: 1,
        prompt: createEasyModeStore.getEasyPromptData(),
      };
    },
    initPresetData() {
      const createEasyModeStore = useCreateEasyModeStore();
      const referMusicStore = userReferMusicStore();
      const vocalMusicStore = userVocalMusicStore();
      const motifDataStore = userMotifDataStore();
      const promptDataStore = usePromptDataStore();
      referMusicStore.initLibraryData();
      vocalMusicStore.initLibraryData();
      createEasyModeStore.initLibraryData();
      promptDataStore.initLibraryData();
      
    }
  },
});
export default useDraftDataStore;
// export const draftDataStore = useDraftDataStore()

import { defineStore } from "pinia";
import { getSongsDetail, getSongsDetailV2 } from "@/api/api/apiStore.js";
import { $$t, $$language } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message/message.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js"
import { baseUrl } from '@/config/apiConfig.js';
import useFeedListStore from "@/store/create/feedListDataStore";
const useStoreDetailData = defineStore({
  id: "storeDetailStoreData",
  state: () => {
    return {
      curDetailIdOrKey: {},
      info: {},
    };
  },
  actions: {
    getInfo(query) {
      const feedlistStore = useFeedListStore()
      return new Promise((resolve) => {
        const api = $$language() == "en" ? getSongsDetailV2 : getSongsDetail;
        api(query).then((res) => {
          if (res.code == 200) {
            if (res?.data?.song) {
              res.data.song.song_url = res.data.song.mp3_url;
            }
            if (res.data?.user) {
              res.data.song.user = res.data.user;
            }
            if (res.data?.feed_id) {
              res.data.song.feed_id = res.data.feed_id;
            }
            if (res.data?.conn_id && res.data?.song?.stream_key) {
              res.data.song.mp3_url = res.data.song.mp3_url || `${baseUrl}/api/live/pipe/audio?stream_key=${res.data?.song?.stream_key}`;
              res.data.song.song_url = res.data.song.mp3_url;
              feedlistStore.doGenerateConnectedSSE(res.data?.conn_id, res.data.song.feed_id, false, true);
            }
            // this.info = res?.data?.song;
          } else if (res.code == 6320) {
            message.error({
              maskClosable: true,
              position: "top",
              content: $$t("common.deleted"),
            });
          }
          resolve(res);
        });
      });
    },
    setCurDetailIdOrKey(curDetailIdOrKey) {
      this.curDetailIdOrKey = curDetailIdOrKey;
    },
    updateDetail(data) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      if (data && (this.curDetailIdOrKey.song_id == data.song_id || this.curDetailIdOrKey.share_key == data.share_key)) {
        this.info = data;
        if (globalPlayDataStore.curPlayData?.song_id == data.song_id) {
          globalPlayDataStore.updatePlayDataItem(data);
        }
      }
    },
    updateSongDetail(data) {
      if (this.info.song_id == data.song_id) {
        const info = Object.assign({}, this.info, data, {
          stream_key: data.stream_key ?? null
        });
        info.song_url = info.mp3_url || data.song_url;
        this.info = info;
      }
    }
  },
});

export default useStoreDetailData;

import { defineStore } from "pinia";
import useFeedListStore from "@/store/create/feedListDataStore.js";
import message from "@/components/functionCallComponent/message/message.js";
import useShopCartStore from "@/store/shopCartStore.js";
import useStoreDetailData from "@/store/storeDetail/storeDetailDataStore.js";
import useMineStore from "@/store/mineDataStore.js";
import useDiscoverStore from "@/store/discover/discoverDataStore.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import globalConfig, { getPipeReadableFlowUrl } from "@/config/config.js";
import tools from "@/utils/tools.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import feedListApi from "@/api/api/feedListApi.js";
import { $$language, $$t } from "@/i18n/i18n.js";
import router from "../router";

export const curPlayGroupPlayPointMap = {
  genreList: "home_genres_songs_play",
  newSongList: "home_new_songs_play",
  trendingList: "home_trending_songs_play",
};

const useGlobalPlayDataStore = defineStore({
  id: "globalPlayDataStore",
  state: () => {
    return {
      playlist: [],
      curPlayData: null,
      tempToggleStatus: false,
      curPlayGroup: "",
      isPlaying: false,

      dataKeyMap: {
        feedlist: {
          title: "title",
          create_time: "create_on",
          duration_milliseconds: "duration",
          like_stat: "like_status",
          song_url: "",
          song_id: "id",
        },
        store: {},
      },
    };
  },
  actions: {
    getDataByFields(data, dataExpression) {
      // get数据内部对应路径数据
      let resData = data;
      let find = false;
      if (String(dataExpression)) {
        const dataFieldArr = String(dataExpression).split(".");
        find = dataFieldArr.every((item) => {
          try {
            resData = resData[item];
            return true;
          } catch (error) {
            return false;
          }
        });
      } else {
        return resData;
      }
      if (find) {
        return resData;
      }
      return undefined;
    },
    setDataByFields(data, dataExpression, value) {
      // set数据内部对应路径数据
      let find = false;
      let setData = data;
      if (String(dataExpression)) {
        const dataFieldArr = String(dataExpression).split(".");
        find = dataFieldArr.every((item) => {
          setData = resData[item];
          if (item === dataFieldArr[dataFieldArr.length - 1]) {
            resData[item] = value;
          }
        });
      }
      return find;
    },
    setDataKeyMap(playGroup, dataKeyMap) {
      this.dataKeyMap[playGroup] = dataKeyMap;
    },
    setIsPlaying(isPlaying) {
      this.isPlaying = isPlaying;
    },
    setCurPlayGroup(playGroup) {
      this.curPlayGroup = playGroup;
    },

    updataLayoutData() {
      const createLayoutStore = useCreateLayoutStore();
      createLayoutStore.updataCreateEditOpenState(
        this.curPlayData ? true : false
      );
    },
    setCurPlayData(curPlayData = null) {
      this.curPlayData = curPlayData;
      this.updataLayoutData();
    },
    setPlayDatas(datas, curPlayItem) {
      this.playlist = datas;
      if (curPlayItem) {
        this.curPlayData = curPlayItem;
        this.updataLayoutData();
      }
    },
    switchCurPlayData(curPlayData) {
      if (this.playlist?.some((item) => item.song_id === curPlayData?.song_id)) {
        if (curPlayData) {
          this.curPlayData = curPlayData;
          this.updataLayoutData();
        }
      } else {
        this.setPlayDatas(
          [curPlayData], curPlayData
        )
      }
    },

    addPlayDatas(datas) {
      datas?.forEach((item) => {
        if (!this.playlist?.some((item2) => item2.song_id === item.song_id)) {
          this.playlist.push(item);
        }
      })
      
      this.playlist = [...this.playlist, ...datas];
    },

    updatePlayData(data) {
      if (this.curPlayData?.id === data.id) {
        this.curPlayData = data;
        this.updataLayoutData();
      } else {
        let index = this.playlist.findIndex((item) => item.id === data.id);
        if (index > -1) {
          this.playlist[index] = data;
        }
      }
    },

    updatePipeReadableFlowUrl(curPlayData) {
      // 触发强制更新 重新绑定播放Url
      this.curPlayData = this.curPlayData ? {
        ...this.curPlayData,
      } : null
    },
    getPlayerKey(data) {
      if (
        (data?.stream_key &&
          GlobalAudioPlayer.currentUrl ==
          getPipeReadableFlowUrl(data?.stream_key) &&
          GlobalAudioPlayer.players[GlobalAudioPlayer.currentUrl]?.isPlaying) ||
        !data?.mp3_url
      ) {
        return getPipeReadableFlowUrl(data?.stream_key);
      } else {
        return getObjectStorageFullUrl(data?.mp3_url);
      }
    },
    switchSong(direction, isDeleted = false) {
      // GlobalAudioPlayer.pause(
      //   getObjectStorageFullUrl(this.curPlayData?.mp3_url)
      // );
      const isPlaying = GlobalAudioPlayer.players[GlobalAudioPlayer.currentUrl]?.isPlaying;
      return new Promise((resolve, reject) => {
        if (direction == "prev") {
          let index = this.playlist.findIndex(
            (item) => item.song_id == this.curPlayData?.song_id
          );
          if (index > 0) {
            this.curPlayData = this.playlist[index - 1];
          } else {
            this.curPlayData = this.playlist[this.playlist.length - 1];
          }
        } else if (direction === "next") {
          let index = this.playlist.findIndex(
            (item) => item.song_id === this.curPlayData?.song_id
          );
          if (index < this.playlist.length - 1) {
            this.curPlayData = this.playlist[index + 1];
          } else {
            this.curPlayData = this.playlist[0];
          }

          this.updataLayoutData();
        }
        if ((!this.curPlayData?.stream_key && !this.curPlayData?.mp3_url) || this.curPlayData?.recall) {
          if (this.playlist?.some((item) => {
            return item.song_id != this.curPlayData?.song_id && (item.stream_key || item.mp3_url) && !item.recall
          })) {
            this.switchSong(direction, isDeleted).then((curPlayData) => {
              resolve(curPlayData);
            })
          } else {
            reject(null);
          }
          return;
        }
        resolve(this.curPlayData);
        const curPlayDataUrl = this.getPlayerKey(this.curPlayData)
        setTimeout(() => {
          if (
            this.playlist?.length == 1 &&
            this.playlist[0].song_id == this.curPlayData?.song_id && !this.curPlayData?.recall
          ) {
            setTimeout(() => {
              if ((isDeleted && isPlaying) || !isDeleted) {
                this.isPlaying = true;
                GlobalAudioPlayer.play(curPlayDataUrl, true, 0);
                setTimeout(() => {
                  GlobalAudioPlayer.seek(curPlayDataUrl, 0);
                }, 2)
              } else {
                this.isPlaying = false;

              }
            }, 1);

          } else {
            if ((isDeleted && isPlaying) || !isDeleted) {
              this.isPlaying = true;
              GlobalAudioPlayer.play(curPlayDataUrl, true, 0);
            } else {
              this.isPlaying = false;
            }
          }
        }, 1);
      });

    },

    tempToggle(status) {
      // true 暂停 false 释放
      this.tempToggleStatus = status;
    },

    removeSongItemAndSwitchNext(song) {
      const storeDetailDataStore = useStoreDetailData();
      if (song?.song_id == storeDetailDataStore?.info?.song_id && ["songDetail", "songDetailOld"].includes(router.currentRoute.value?.name) && isDelete) {
        message.info({
          content: "create.cur_song_generate_failed",
          position: "top"
        });
      }
      this.playlist = this.playlist?.filter((item) => item?.song_id != song?.song_id);
      const curPlayData = this.curPlayData;
      if (song && song?.song_id == this.curPlayData?.song_id) {
        this.switchSong("next", true).then((curPlayData) => {
          if (song?.song_id == curPlayData?.song_id) {
            this.curPlayData = null;
            globalAudioPlayer.pause(this.getPlayerKey(curPlayData));
          }
          this.removePlayDataItem(song);
        }).catch((e) => {
          console.error("removeSongItemAndSwitchNext_doSwitch", e);
        });
      }
    },

    removePlayDataItem(data) {
      if (GlobalAudioPlayer.currentUrl == getObjectStorageFullUrl(data?.mp3_url)) {
        GlobalAudioPlayer.pause(getObjectStorageFullUrl(data?.mp3_url));
      }
      if (GlobalAudioPlayer.currentUrl?.includes("stream_key=") && getPipeReadableFlowUrl(data?.stream_key) == GlobalAudioPlayer.currentUrl) {
        GlobalAudioPlayer.pause(getObjectStorageFullUrl(getPipeReadableFlowUrl(data?.stream_key)));
      }
      if (this.curPlayData?.song_id == data?.song_id) {
        this.curPlayData = null;
      }
      this.playlist = this.playlist?.filter((item) => item?.song_id != data?.song_id);
    },

    updatePlayDataItem(data) {
      if (data) {
        if (!this.curPlayData) return;
        if (this.curPlayData?.song_id == data.song_id) {
          this.curPlayData = Object.assign({}, this.curPlayData, data);
        }
        // console.log("this.playlist", this.playlist);
        this.playlist?.some((item) => {
          if (item?.song_id == data.song_id) {
            Object.assign(item, data);
          }
        });
        this.updataLayoutData();
      }


      this.curPlayData = this.curPlayData ? Object.assign({}, this.curPlayData) : null;
    },
    updataOtherFromData(data) {
      switch (this.curPlayGroup) {
        case "feedlist":
          const feedlistStore = useFeedListStore();
          feedlistStore.updateFeedSongItem(data, true);
          break;
        case "":
          // @TODO:choumingzhu
          break;
        default:
          break;
      }
    },
    doLike(smItem) {
      // const shopCartStore = useShopCartStore();
      const storeDetailDataStore = useStoreDetailData();
      const discoverStore = useDiscoverStore();
      const mineStore = useMineStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .favoriteSong({
            song_id: smItem.song_id,
            state:
              smItem.is_liked ?? smItem.favorite_state
                ? (smItem.is_liked ?? smItem.favorite_state) == 1
                  ? 2
                  : 1
                : this.renderFavorite_state(smItem?.favorite_state),
          })
          .then((res) => {
            if (res.code == 200) {
              Object.assign(smItem, {
                favorite_state:
                  (smItem.is_liked ?? smItem.favorite_state) == 1 ? 2 : 1,
                is_liked:
                  (smItem.is_liked ?? smItem.favorite_state) == 1 ? 2 : 1,
              });
              this.updatePlayDataItem(smItem);
              this.updataOtherFromData(smItem);
              // shopCartStore.updateShopLike(
              //   smItem.song_id,
              //   smItem.favorite_state
              // );
              discoverStore.updateGlobalPlayFavorite(
                smItem.song_id,
                smItem.favorite_state
              );
              // 详情页的数据结构不同，需要单独处理
              storeDetailDataStore.updateDetail({
                ...storeDetailDataStore.info,
                is_liked: smItem.favorite_state == 1 ? true : false,
              });
              console.log("smItem", smItem);
              mineStore.deleteShopList(smItem);
              resolve();
            } else if (res.code == 6332) {
              message.error({
                content: $$t("create.like_song_has_been_deleted"),
                position: "top",
              });
            } else {
              // message.error({ content: "Save failed" });
              reject("Save failed");
            }
          })
          .catch((err) => {
            // message.error({ content: "Save failed" });
            reject("Save failed");
          });
      });
    },
    renderFavorite_state(favorite_state) {
      if (favorite_state == 1) {
        return 2;
      } else {
        return 1;
      }
    },
    doShare(smItem) {
      return new Promise((resolve, reject) => {
        tools
          .copyToClipboard(
            `${globalConfig.baseOrigin}/song-detail/${smItem.share_key}?is_from_share=1`
          )
          .then((res) => {
            // message.success({
            //   content: "Song link copied",
            //   position: "top",
            // });
            resolve();
          })
          .catch(() => {
            reject("Copy failed");
          });

        feedListApi.shareSongReport({
          song_id: smItem.song_id,
          channel: "link",
        });
        // .then((res) => {
        //   if (res.code == 200) {
        //       tools
        //         .copyToClipboard(
        //           `${globalConfig.baseOrigin}/song-detail/${res.data.share_key}?is_from_share=1`
        //         )
        //         .then((res) => {
        //           // message.success({
        //           //   // maskClosable: true,
        //           //   content: "Song link copied",
        //           //   // position: "top",
        //           // });
        //           resolve();
        //         })
        //         .catch((err) => {
        //           reject();
        //         });
        //     }
        // })
        // .catch((err) => {
        //   // message.error({ content: "Save failed" });
        //   reject();
        // });
      });
    },

    doAddToShoppingCart(smItem) {
      return new Promise((resolve) => {
        const shopCartStore = useShopCartStore();
        const storeDetailDataStore = useStoreDetailData();
        const mineStore = useMineStore();
        shopCartStore.doAddCart(smItem).then((res) => {
          if (res) {
            resolve(res);
            const { code } = res;
            if (code == 200) {
              // 改变全局播放器加购状态
              this.updatePlayDataItem({
                ...smItem,
                cart_state: 1,
              });
              // 改变商城列表的状态
              shopCartStore.updateShopCart(smItem.song_id, 1);

              // 改变详情状态
              storeDetailDataStore.updateDetail(smItem);
              // 改变喜欢列表的状态
              mineStore.updateShopCart(smItem.song_id, 1);
            } else if (res.code == 6305) {
              shopCartStore.deleteShopList(smItem.id);
              updatePlayDataItem({
                ...smItem,
                sold_state: 4,
              });
            } else if (res.code == 6306) {
              shopCartStore.deleteShopList(smItem.id);
              updatePlayDataItem({
                ...smItem,
                sold_state: 4,
              });
            }
          }
        });
      });
    },

    doRegenerate(smItem) {
      const feedListDataStore = useFeedListStore();

      feedListDataStore.reGenerateSmItem(smItem);
    },
  },
});

export default useGlobalPlayDataStore;

// const globalPlayDataStore = useGlobalPlayDataStore();

<template>
  <router-view></router-view>
</template>
<script setup>
import { watch, reactive, onMounted, ref, getCurrentInstance, nextTick } from "vue"
import { storeToRefs } from "pinia"
import i18n from "@/i18n/i18n.js"
import useUserDataStore from "@/store/userDataStore"
import GlobalConfig from "@/config/config.js"
import { getCookie } from "@/utils/cookie.js"
import apiUser from "@/api/api/apiUser.js"
import router from "@/router/index.js"
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js"
import useHomeDataStore from "@/store/homeDataStore.js"
import useDraftDataStore from "@/store/create/draftDataStore.js"
import useLyricDataStore from "@/store/create/lyricDataStore.js"
import tools from "@/utils/tools.js"
import { $$language, $$t } from "@/i18n/i18n.js"
import message from "@/components/functionCallComponent/message/message.js"
import { funComponentList } from "@/components/functionComp/index"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js"
import useDiscoverDataStore from "@/store/discover/discoverDataStore.js"
import useCreatVideoResultDataStore from "@/store/createVideo/creatVideoResultDataStore"
import { isDev, isMobileDevice } from "@/utils/tools.js"
import { getLatestUtmSourceFromCookie } from "@/api/net/config.js"
import globleConfig from "@/config/config.js"

import { useRouter, useRoute } from "vue-router"
import { jwtDecode } from "jwt-decode"
import { tt } from "@/utils/tt.js"
import { googleOneTap, decodeCredential } from "vue3-google-login"
import showVideoResult from "@/components/videoResult/videoResult.js"
// import { AwsRum } from "aws-rum-web"

const globalProperties = getCurrentInstance()?.appContext.config.globalProperties

const createLayoutStore = useCreateLayoutStore()
const creatVideoResultDataStore = useCreatVideoResultDataStore()

const { last_generate_video } = storeToRefs(creatVideoResultDataStore)
const Router = useRouter()
const route = useRoute()
const draftDataStore = useDraftDataStore()
const lyricDataStore = useLyricDataStore()

const userDataStore = useUserDataStore()
const discoverDataStore = useDiscoverDataStore()

let k_sso_token = getCookie("k_sso_token") ?? ""
const registCacheData = (_this) => {
  window.addEventListener("beforeunload", function (event) {
    const homeDataStore = useHomeDataStore()
    homeDataStore.abortSse()
    // createLayoutStore.cacheLayoutData();
    stateData.ed = new Date().getTime()
    // globalProperties.logCustomEvent("single_page_stay_time", {
    //   stay_time: (stateData.ed - stateData.sd) / 1000,
    //   type: "refresh"
    // });
    // if (
    //   tools.isProd() &&
    //   draftDataStore.lastGenerateDraftData?.lyrics != lyricDataStore.lyric &&
    //   !!lyricDataStore.lyric
    // ) {
    //   const confirmationMessage =
    //     "Are you sure you want to exit? Ungenerated edits will be lost?";
    //   event.returnValue = confirmationMessage; // For most browsers
    //   return confirmationMessage; // For some older browsers
    // }
    return false
  })
  window.addEventListener("load", function (event) {
    // 页面完全加载后执行的代码
    createLayoutStore.initCacheLayoutData()
    console.log("页面已加载")
  })
}

const codeReport = async () => {
  // if ($$language() == "cn") return
  const code = tools.getParameterByName("channel") ?? ""
  console.log("code", code)
  if (!code) return
  const res = await apiUser.userCodePeport({ code: code })
  switch (res.code) {
    case 200:
      message.success({
        position: "top",
        content: $$t("common.saveSuccess"),
      })
      break
    case 6318:
      message.error({
        maskClosable: true,
        position: "top",
        content: $$t("common.linkInvalid"),
      })
      break
  }
}

const getQueryParameter = (name) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

const getAccessToken = (url) => {
  console.log(url.split("#"), "getAccessTokengetAccessTokengetAccessToken")
  const params = new URLSearchParams(url.split("#")[1])
  return params.get("access_token")
}

const updateAuthData = (_data) => {
  userDataStore.upDataAuthData(_data.auth)
  userDataStore.updateLoginStatus(true)
}

const loginByAccessToken = async (accessToken) => {
  const { data, code, msg } = await apiUser.userLogin({
    mode: "discord",
    token: accessToken,
    source: getLatestUtmSourceFromCookie() ?? "",
    signup_source: userDataStore.getSignupSource(),
    activity_source: userDataStore.getActivitySource(),
  })
  if (code === 200) {
    console.log("第三方登录成功")
    window.location.hash = ""
    updateAuthData(data)
    if (data?.is_new) {
      invite()
    }
  } else if (code == 6401) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "Deleted accounts cannot be registered within 14 days.",
    })
  } else {
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}

const loginByIdToken = async (token) => {
  const decoded = jwtDecode(token)
  console.log("decoded====>", decoded)
  const { data, code, msg } = await apiUser.userLogin({
    mode: "apple",
    extra_token: getQueryParameter("code") ?? "",
    token: decoded?.sub ?? "",
    source: getLatestUtmSourceFromCookie() ?? "",
    signup_source: userDataStore.getSignupSource(),
    activity_source: userDataStore.getActivitySource(),
  })
  if (code === 200) {
    console.log("第三方登录成功")
    updateAuthData(data)
    if (data?.is_new) {
      invite()
    }
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    params.delete('id_token');
    url.search = params.toString();
    window.history.replaceState({}, '', url.toString());
  } else if (code == 6401) {
    message.error({
      maskClosable: true,
      position: "top",
      content: "Deleted accounts cannot be registered within 14 days.",
    })
  } else {
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}

const invite = () => {
  const invite = localStorage.getItem("invite_code") ?? ""
  if (invite) {
    userDataStore.inviteCodePoint(invite)
  }
}

const threeLoginStateGet = async () => {
  const id_token = getQueryParameter("id_token") ?? ""
  if (id_token) {
    await loginByIdToken(id_token)
    createLayoutStore.toggleCreateEditOpenState(true)
    return
  }
  const accessToken = getAccessToken(window.location.href) ?? ""
  if (accessToken) {
    await loginByAccessToken(accessToken)
    createLayoutStore.toggleCreateEditOpenState(true)
    return
  }

  userDataStore.init()

  googleOneTapLogin()
}

const stateData = {
  sd: 0, // 开始时间
  svd: 0, // tab后台开始时间
  hed: 0, // tab后台开始时间
  ved: 0, // tab前台开始时间
  ed: 0, // 结束时间
  perPointReportListMaxCount: 500, // 每次上报的次数
}

const doReportDiscoverList = () => {
  const { ids, control_features_ids } = discoverDataStore.reportDiscoverList()

  if (ids?.length) {
    if (ids?.length > stateData.perPointReportListMaxCount) {
      const chunks = tools.splitArray(ids, stateData.perPointReportListMaxCount)
      chunks.forEach((chunk) => {
        globalProperties.logCustomEvent("home_single_song_show", {
          home_show_song_ids: chunk,
          control_feature_song_ids: control_features_ids.filter((item) => chunk.includes(item)),
        })
      })
    } else {
      globalProperties.logCustomEvent("home_single_song_show", {
        home_show_song_ids: ids,
        control_feature_song_ids: control_features_ids,
      })
    }
    discoverDataStore.clearShowList()
  }
}

const initAnalyse = () => {
  let i = 0
  let sd = new Date().getTime()
  let timer = setInterval(() => {
    i = i + 1
    switch (i * 5) {
      case 5:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "5",
        })
        break
      case 10:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "10",
        })
        break
      case 20:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "20",
        })
        break
      case 30:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "30",
        })
        doReportDiscoverList()
        break
      case 60:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "60",
        })

      case 180:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "180",
        })

        doReportDiscoverList()
        break
      case 360:
        globalProperties.logCustomEvent("page_stay_time_all", {
          stay_time: "360",
        })
        localStorage.removeItem(`${globleConfig.appname}_create_data`)
        doReportDiscoverList()
        break
      default:
        if (i * 5 > 360 && (i * 5 - 360) % 180 == 0) {
          globalProperties.logCustomEvent("page_stay_time_all", {
            stay_time: (i * 5).toString(),
          })
          doReportDiscoverList()
        }
        break
    }
  }, 5 * 1000)
  globalProperties.logViewEvent("web_app")
  stateData.sd = new Date().getTime()
  stateData.svd = stateData.sd
  stateData.hed = stateData.sd
  stateData.ved = stateData.sd
  stateData.ed = stateData.sd
  window.addEventListener("visibilitychange", function () {
    console.log("visibilitychange", document.visibilityState, new Date().getTime())
    if (document.visibilityState === "visible") {
      stateData.ved = new Date().getTime()
      stateData.svd = stateData.ved
      stateData.sd += stateData.ved - stateData.hed
    } else {
      stateData.hed = new Date().getTime()
      globalProperties.logCustomEvent("single_page_stay_time", {
        stay_time: ((stateData.hed - stateData.svd) / 1000).toFixed(2),
      })
    }
  })
}

const inviteCode = () => {
  const inviteCode = getQueryParameter("invite_code")
  if (inviteCode) {
    localStorage.setItem("invite_code", inviteCode)
  }
  const activityCode = getQueryParameter("activity")
  if (activityCode) {
    localStorage.setItem("activity_code", activityCode)
  }
}

const googleOneTapLogin = async () => {
  if (userDataStore.isLogin || isMobileDevice()) {
    return
  }
  globalProperties.logViewEvent("auto_google_login")
  googleOneTap()
    .then((response) => {
      // const userData = decodeCredential(response.credential)
      userDataStore.googleLogin(response?.credential, true).then(() => {
        globalProperties.logClickEvent("auto_google_login")
        const loginDom = document.querySelector(".third-login-dom")
        if (loginDom) {
          loginDom.remove()
        }
      })
    })
    .catch((error) => {
      console.log("Handle the error", error)
    })
}

watch(
  () => {
    return userDataStore.appLanguage
  },
  () => {
    nextTick(() => {
      window.$$curLanguage = userDataStore.appLanguage
      document.body.setAttribute("data-lang", userDataStore.appLanguage)
      $$language(userDataStore.appLanguage)
      userDataStore.init()
      draftDataStore.initPresetData();
    })
  }
)

watch(
  () => {
    return last_generate_video.value
  },
  () => {
    const val = last_generate_video.value?.video?.state
    if (val == 3 || val == 4) {
      if (createLayoutStore.createRenderType == "pc") {
        nextTick(() => {
          if (route.meta.title != "createVideo") {
            globalProperties.logViewEvent("activity_alert", {
              activity_name: "christmas_lip",
            })
            showVideoResult({
              type: val == 3 ? "success" : "fail",
            })
          }
        })
      }
    }
  }
)

watch(
  () => {
    return userDataStore.isLogin
  },
  () => {
    initVideoData()
  }
)

const initVideoData = () => {
  if (userDataStore.isLogin) {
    creatVideoResultDataStore.init({
      listRenderType: "createdresult",
    })
  }
}

// const aws_report = () => {
//   try {
//     const config = {
//       sessionSampleRate: 0.01,
//       identityPoolId: "us-east-1:a1876722-daf4-419d-b8fe-4638a05d8b82",
//       endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
//       telemetries: ["performance", "errors", "http"],
//       allowCookies: true,
//       enableXRay: false,
//     }

//     const APPLICATION_ID = "0a141f44-25c8-4856-9729-4ce2b434f377"
//     const APPLICATION_VERSION = "1.0.0"
//     const APPLICATION_REGION = "us-east-1"

//     const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
//   } catch (error) {
//     // Ignore errors thrown during CloudWatch RUM web client initialization
//   }
// }

onMounted(async () => {
  window.$$curLanguage = userDataStore.appLanguage
  document.body.setAttribute("data-lang", userDataStore.appLanguage)
  registCacheData()
  const cookie = getQueryParameter("auth_key") ?? ""
  if (cookie) {
    document.cookie = `skm_session${isDev() ? "_test" : ""}=${cookie};path=/`
  }

  threeLoginStateGet()

  codeReport()

  inviteCode()

  if (GlobalConfig.appname == "YinFeng") {
    document.body.classList.add("yinfeng")
  }
  initAnalyse()
  tt()
  // aws_report()
  setTimeout(() => {
    if (route.meta.title != "createVideo") {
      initVideoData()
    }
  }, 3 * 1000)
  if (!userDataStore.isLogin && getQueryParameter("invite_code")) {
    userDataStore.checkLoginStatus({
      rightType: 'invite'
    })
  }
})
</script>
<style lang="scss">
.lottie-animation-contaniner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .lottie-animation-content {
    height: 80px;
    width: 80px;
  }
}
</style>

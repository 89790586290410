import { defineStore } from "pinia";
import apiVideo from "@/api/api/apiVideo.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { showActionDialog } from "@/components/functionCallComponent/action/action.js";
import ShowInviteDialog from "@/components/inviteDialog/inviteDialog.js";
import Router from "@/router/index.js";
import { inMurekaApp } from "@/utils/tools.js"
import { jsBridge } from "@/utils/jsBridge.js"

import {
  getProducts,
  checkFirstPurchase,
  manageSubscription,
  getInvitePop,
} from "@/api/api/apiCredit.js";
import objectStorage, {
  getObjectStorageFullUrl,
} from "@/lib/objectStorage/objectStorage.js";
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";

import useUserDataStore from "@/store/userDataStore.js";

import useCreatVideoEditDataStore from "@/store/createVideo/creatVideoEditDataStore.js";

// const creatVideoEditDataStore = useCreatVideoEditDataStore();

const TabDataSuffixLists = ["createdresult"];
export const TabDataSuffixMap = {
  createdresult: "createdresult",
};

const FeedListApiMap = {
  createdresult_true: apiVideo.getVideoFeedList,
  createdresult_false: apiVideo.getVideoFeedList,
};

const creatVideoResultDataStore = defineStore({
  id: "creatVideoResultDataStore",
  state: () => {
    return {
      feedlist_createdresult: [],
      total_createdresult: 0,
      loadMoreId_createdresult: "",
      isloading_createdresult: true,
      hasInit_createdresult: false,
      hasMore_createdresult: false,

      curSseConnectIdMap: {},
      curGenerateFeedIdList: [],
      sseGeneratingMap: {},
      curGenerateFeedCount: 0,
      curGeneratingFeedIdList: [],

      generating: false,

      last_generate_call_conn_id: "", // 上一次生成视频的conn_id

      last_generate_video: null,
    };
  },
  actions: {
    clearData() {
      this.feedlist_createdresult = [];
      this.total_createdresult = 0;
      this.loadMoreId_createdresult = "";
      this.isloading_createdresult = true;
      this.hasInit_createdresult = false;
      this.hasMore_createdresult = false;

      this.curSseConnectIdMap = {};
      this.curGenerateFeedIdList = [];
      this.curGenerateFeedCount = 0;
      Object.keys(this.sseGeneratingMap).forEach((key) => {
        this.sseGeneratingMap[key]?.abort();
      });
      this.sseGeneratingMap = {};
      this.curGeneratingFeedIdList = [];
      this.generating = this.getIsGenerating();
      this.last_generate_call_conn_id = "";
      this.last_generate_video = null;
    },

    initFeedData(data, params) {
      const { listRenderType } = params;

      this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = data?.list ?? [];
      this[`total_${TabDataSuffixMap[listRenderType]}`] = data?.total ?? 0;
      if (this.initGenerateFeedData && listRenderType == "createdresult") {
        if (
          !this[`feedlist_${TabDataSuffixMap[listRenderType]}`].some(
            (item) => item.video_id === this.initGenerateFeedData?.video_id
          )
        ) {
          this[`feedlist_${TabDataSuffixMap[listRenderType]}`].unshift(
            this.initGenerateFeedData
          );
          this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = [].concat(
            this[`feedlist_${TabDataSuffixMap[listRenderType]}`]
          );
        }
        this.initGenerateFeedData = null;
      }
      this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`] =
        data?.last_id ?? "";
      if (listRenderType == "createdresult") {
        this.updateGenerateFeedDataSse(this.feedlist_createdresult, true);
      }
      return this[`feedlist_${TabDataSuffixMap[listRenderType]}`];
    },

    updateGenerateFeedDataSse(datas, isInit) {
      for (let i = datas.length - 1; i >= 0; i--) {
        if ([1, 2].indexOf(datas[i]?.video?.state) >= 0 && datas[i]?.conn_id) {
          this.doGenerateConnectedSSE.call(
            this,
            datas[i]?.conn_id,
            datas[i]?.video?.video_id
          );
          if (isInit) {
            this.last_generate_video = datas[i];
          }
        } else {
          if (this.curGenerateFeedIdList.some((item) => {
            return datas[i]?.video?.video_id == item
          })) {
            this.curGenerateFeedCount--;
            this.curGenerateFeedIdList.splice(
              this.curGenerateFeedIdList.indexOf(datas[i]?.video?.video_id),
              1
            );
            this.curGeneratingFeedIdList.splice(
              this.curGeneratingFeedIdList.indexOf(datas[i]?.video?.video_id),
              1
            );
            delete this.curSseConnectIdMap[datas[i]?.video?.video_id];
            this.sseGeneratingMap[datas[i]?.video?.video_id]?.abort();
            delete this.sseGeneratingMap[datas[i]?.video?.video_id];
          }
        }

        if (this.last_generate_video?.video?.video_id == datas[i]?.video?.video_id) {
          this.last_generate_video = Object.assign({}, this.last_generate_video, datas[i]);
        }
      }
    },

    getFeedList(params, done, errordone) {
      const { listRenderType } = params;
      this[`isloading_${TabDataSuffixMap[listRenderType]}`] = true;
      this[`params_${listRenderType}`] = params;
      if (!params.title) {
        delete params.title;
      }
      return new Promise(async (resolve, reject) => {
        FeedListApiMap[
          `${listRenderType}_${!!this[`params_${listRenderType}`].title}`
        ](params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this[`hasMore_${TabDataSuffixMap[listRenderType]}`] = !!data.more;
              let resData = this.initFeedData(data, params);
              resolve(resData);
              done(resData, !data.more);
            } else {
              errordone();
            }
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
          })
          .catch((e) => {
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
            reject(false);
          });
      });
    },

    initialize() {
      this.init(this[`params_${listRenderType}`] ?? {});
    },

    init(params = {}, done, errordone, isSearch, showLoading) {
      const { listRenderType } = params;
      let closeMsg = null;
      return new Promise((resolve, reject) => {
        if (showLoading) {
          closeMsg = message.loading({
            position: "center",
            content: "Loading...",
          });
        }

        this.getFeedList(params, done, errordone, isSearch ?? true)
          .then((datas) => {
            this[`hasInit_${TabDataSuffixMap[listRenderType]}`] = true;
            resolve(datas);
            closeMsg && closeMsg.clear();
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            reject(err);
          });
      });
    },

    async refresh(params, done, errordone) {
      const { listRenderType } = params;
      return new Promise((resolve, reject) => {
        if (this[`isloading_${TabDataSuffixMap[listRenderType]}`]) {
          done && done();
          return;
        }
        this.getFeedList(params, done, errordone)
          .then((datas) => {
            resolve(datas);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadMore(done, errordone, listRenderType) {
      return new Promise((resolve, reject) => {
        this[`params_${listRenderType}`].last_id =
          this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`];
        if (this[`isloading_${TabDataSuffixMap[listRenderType]}`]) {
          done && done();
          reject("loading");
          return;
        }
        if (
          !this[`params_${listRenderType}`].last_id ||
          !this[`hasMore_${TabDataSuffixMap[listRenderType]}`]
        ) {
          done && done([], true);
          reject("no more");
          return;
        }
        this[`isloading_${TabDataSuffixMap[listRenderType]}`] = true;
        FeedListApiMap[
          `${listRenderType}_${!!this[`params_${listRenderType}`].title}`
        ](this[`params_${listRenderType}`])
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`] =
                data.last_id;
              this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = []
                .concat(this[`feedlist_${TabDataSuffixMap[listRenderType]}`])
                .concat(data.list ?? []);
              done(data.list ?? [], !data.more);
              this[`hasMore_${TabDataSuffixMap[listRenderType]}`] = !!data.more;
              resolve(data.list ?? []);
              if (listRenderType == "createdresult") {
                this.updateGenerateFeedDataSse(data.list);
              }
            } else {
              reject(msg ?? "error qquery code");
              errordone();
            }
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
          })
          .catch((err) => {
            reject(err);
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
            errordone();
          });
      });
    },
    getIsGenerating() {
      return this.curGenerateFeedIdList?.length;
    },
    insertGenerateCard(data) {
      if (
        !this.feedlist_createdresult.some(
          (item) => item?.video?.video_id == data?.video?.video_id
        )
      ) {
        this.feedlist_createdresult.unshift(data);
        this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
      }
    },
    checkGenerateError(
      {
        res,
        isGenerate,
        feedData,
        mode,
        membership_source1,
        membership_source2,
      },
      logParams
    ) {
      switch (res.code) {
        case 6101:
          message.info({
            position: "top",
            content: "Please wait for the video to finish generating",
          });
          break;

        case 6310:
          this.goCredits(null, { membership_source2, membership_source1 });
          break;

        case 6301:
        case 6302:
        case 6308:
          message.error({
            position: "top",
            content: "Generate failed",
          });

          break;
        case 6320:
          message.error({
            position: "top",
            content: "The image is unavailable, please change it",
          })
          break;
        case 6323:
          message.error({
            position: "top",
            content: "Please wait for the video to finish generating",
          })
          break;

        case 9008:
          message.error({
            position: "top",
            content: "Too frequently, please try again later."
          });
          break;

        // message.info({
        //   position: "top",
        //   content: "Unrecognizable image. Please replace.",
        // });

        default:
          message.error({
            position: "top",
            content: "Generate failed",
          });
          break;
      }
    },
    goCredits(
      content,
      {
        membership_source1,
        membership_source2,
        show_credits = false
      },
      isToSubscribe
    ) {
      const userDataStore = useUserDataStore();
      const is_vip = userDataStore?.userInfo?.user?.is_vip;

      const showCreditsData = (show_credits && is_vip) ? {
        show_credits: true,
      } : {}
      if (isToSubscribe) {
        if (inMurekaApp()) {
          jsBridge("view.subscribe", 'activity', 'christmas_lip')
        } else {
          Router.push({
            name: "subscribe",
            query: {
              buy_type: is_vip ? "credits" : "vip",
              membership_source1: membership_source1 ?? "create",
              membership_source2,
              ...showCreditsData
            },
          });
        }
        return;
      }
      showActionDialog({
        title: "Run out of credits?",
        content: content ?? "Subscribe Pro plan to get more credits!",
        cancelLabel: "Cancel",
        confirmLabel: "Subscribe",
        // is_vip
        //   ? $$t("create.buy_credits")
        //   : $$t("create.open_vip"),
        confirm: (closeAction) => {
          closeAction();
          if (inMurekaApp()) {
            jsBridge("view.subscribe", 'activity', 'christmas_lip')
            return
          }
          Router.push({
            name: "subscribe",
            query: {
              buy_type: is_vip ? "credits" : "vip",
              membership_source1: membership_source1 ?? "create",
              membership_source2,
              ...showCreditsData
            },
          });
          firebaseUtils.logClickEvent("credits_useup", {
            is_vip: is_vip ? 1 : 0,
          });
        },
        cancel: (closeAction) => {
          if (userDataStore?.isLogin && !inMurekaApp()) {
            getInvitePop({}).then((res) => {
              if (res.code == 200) {
                if (res?.data?.pop) {
                  ShowInviteDialog({ type: 'activity_page_link' });
                }
              }
            });
          }
          closeAction();
        },
      });
      // message.error({
      //   position: "top",
      //   content: $$t("create.credits_not_enough"),
      // });
    },
    doGernerate() {
      const creatVideoEditDataStore = useCreatVideoEditDataStore();
      const userDataStore = useUserDataStore();
      return new Promise(async (resolve, reject) => {
        if (userDataStore?.credits < 2) {
          this.goCredits(null, {
            membership_source1: "activity",
            membership_source2: "christmas_lip",
            show_credits: true,
          });
          return reject("credits not enough");
        }
        if (this.generating) {
          message.info({
            position: "top",
            content: "Please wait for the video to finish generating",
          })
          return reject("generating");
        }
        const { lyrics, imageUploadData, genre, vocalGender, christmasHat } =
          creatVideoEditDataStore.getVideoEditData();
        const params = {
          lyrics: lyrics,
          cover_url: imageUploadData?.pathKey,
          with_hat: christmasHat == 1 ? true : false,
          prompt: {
            content: `${genre}, ${vocalGender}`,
          }
        };
        this.curGenerateFeedCount += 1;
        apiVideo
          .doGenerateVideo(params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this.last_generate_call_conn_id = data.conn_id;
              this.insertGenerateCard(data);
              this.last_generate_video = data;
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.video?.video_id);
              userDataStore.refreshUserAccount();
              resolve(data);
              if (inMurekaApp()) {
                jsBridge("create.video")
              }
              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                mode: "3",
                // type: isInit ? "not_sign_in" : "sign_in",
                musicid: data.video?.video_id,
                prompt: params.prompt.content,
                cover_url: getObjectStorageFullUrl(imageUploadData?.pathKey),
                activity_name: "christmas_lip"
              });
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError({
                res,
                membership_source1: "activity",
                membership_source2: "christmas_lip",
              });
              this.generating = this.getIsGenerating();
              reject("fGenerate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            this.generating = this.getIsGenerating();
            reject(err);
            message.error({
              position: "top",
              content: "Generate failed",
            });
          });
      });
    },



    doReGernerate(pitem, video) {
      const creatVideoEditDataStore = useCreatVideoEditDataStore();
      const userDataStore = useUserDataStore();
      return new Promise(async (resolve, reject) => {
        if (userDataStore?.credits < 2) {
          this.goCredits(null, {
            membership_source1: "activity",
            membership_source2: "christmas_lip",
            show_credits: true,
          });
          return reject("credits not enough");
        }
        if (this.generating) {
          message.info({
            position: "top",
            content: "Please wait for the video to finish generating",
          })
          return reject("generating");
        }
        this.curGenerateFeedCount += 1;
        apiVideo
          .doReGenerateVideo({
            video_id: video.video_id,
          })
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this.last_generate_call_conn_id = data.conn_id;
              this.updateGenerateCard(data, data.video?.video_id);
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.video?.video_id);
              userDataStore.refreshUserAccount();
              if (inMurekaApp()) {
                jsBridge("create.video")
              }
              resolve(data);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError({
                res,
                membership_source1: "activity",
                membership_source2: "christmas_lip",
              });
              this.generating = this.getIsGenerating();
              reject("fGenerate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            this.generating = this.getIsGenerating();
            reject(err);
            message.error({
              position: "top",
              content: "Generate failed",
            });
          });
      });
    },
    doCancelGenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        showActionDialog({
          // title: "Title",
          content: "Are you sure you want to cancel the generation?",
          confirmLabel: "Confirm",
          cancelLabel: "Cancel",
          confirm: (closeAction) => {
            closeAction();
            apiVideo
              .doCancelGenerateVideo({
                video_id: item.video_id,
              })
              .then((res) => {
                if (res.code == 200) {
                  if (this.curGenerateFeedIdList.includes(item?.video_id)) {
                    this.sseGeneratingMap[item.video_id]?.abort();
                    delete this.sseGeneratingMap[item.video_id];
                    this.curGenerateFeedIdList.splice(
                      this.curGenerateFeedIdList.indexOf(item.video_id),
                      1
                    );
                    this.curGenerateFeedCount -= 1;
                  }
                  let index = this.feedlist_createdresult.findIndex(
                    (itemF) => itemF?.video.video_id == item.video_id
                  );
                  if (index > -1) {
                    this.feedlist_createdresult.splice(index, 1);
                    this.feedlist_createdresult = [].concat(
                      this.feedlist_createdresult
                    );
                    resolve();
                  }
                  this.generating = this.getIsGenerating();
                  userDataStore.refreshUserAccount();
                } else {
                  message.error({
                    position: "top",
                    content: "Cancel failed, please try again",
                  });
                  reject("feedCard Cancel Generate failed");
                }
              })
              .catch((err) => {
                message.error({
                  position: "top",
                  content: "Cancel failed, please try again",
                });
                reject(err);
              });
          },
          cancel: (closeAction) => {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },
    updateGenerateCard(item, video_id) {
      if (this.feedlist_createdresult.some(
        (itemF) => {
          if (itemF?.video?.video_id == video_id) {
            itemF.video = Object.assign(itemF?.video ?? {}, item.video);
            if (this.last_generate_video?.video?.video_id == video_id && video_id) {
              this.last_generate_video = Object.assign({}, this.last_generate_video, itemF);
            }
            return true;
          }
          return false;
        }
      )) {
        this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
      }



    },
    async doGenerateConnectedSSE(conn_id, video_id, isQueueCall) {
      const userDataStore = useUserDataStore();
      if (!this.curGenerateFeedIdList.includes(video_id) || isQueueCall) {
        if (!isQueueCall) {
          this.curSseConnectIdMap[video_id] = conn_id;
          this.curGenerateFeedIdList.push(video_id);
        }
        if (
          this.curGeneratingFeedIdList.length <= 1 &&
          !this.sseGeneratingMap[video_id] &&
          this.curGenerateFeedIdList.includes(video_id)
        ) {
          this.generating = true;
          this.curGeneratingFeedIdList.push(video_id);
          try {
            this.sseGeneratingMap[video_id] =
              await sseGenerateApi.doSseGetGenerate({
                params: `conn_id=${conn_id}`,
                onOpen(e) { },
                onMessage: (res) => {
                  if (["pgc_christmas_video"].indexOf(res.event) >= 0) {
                    const feeddata = JSON.parse(res.data ?? "{}");
                    this.updateGenerateCard({
                      video: {
                        ...feeddata,
                      }
                    }, video_id);
                    if (feeddata?.state == 4 || feeddata?.state == 6) {
                      userDataStore.refreshUserAccount();
                    }
                  } else {
                    if (res.event == "done") {
                      this.curGenerateFeedIdList.splice(
                        this.curGenerateFeedIdList.indexOf(video_id),
                        1
                      );
                      this.generating = this.getIsGenerating();
                      this.curGeneratingFeedIdList.splice(
                        this.curGeneratingFeedIdList.indexOf(video_id),
                        1
                      );
                      let noGeneratingFeedIdList = this.curGenerateFeedIdList.filter(
                        (item) => !this.curGeneratingFeedIdList.includes(item)
                      );

                      if (noGeneratingFeedIdList.length) {
                        this.doGenerateConnectedSSE(
                          this.curSseConnectIdMap[noGeneratingFeedIdList[0]],
                          noGeneratingFeedIdList[0],
                          true
                        );
                      }
                      this.curGenerateFeedCount -= 1;
                      this.sseGeneratingMap[video_id]?.abort();
                      delete this.sseGeneratingMap[video_id];
                    }
                  }
                },
                onError: (e) => {
                },
                onClose: (e) => {
                  delete this.sseGeneratingMap[video_id];
                  this.curGenerateFeedIdList.splice(
                    this.curGenerateFeedIdList.indexOf(video_id),
                    1
                  );
                  this.curGeneratingFeedIdList.splice(
                    this.curGeneratingFeedIdList.indexOf(video_id),
                    1
                  );
                  this.generating = this.getIsGenerating();
                },
              });
          } catch (e) {
            this.curGenerateFeedIdList.splice(
              this.curGenerateFeedIdList.indexOf(video_id),
              1
            );
            this.curGeneratingFeedIdList.splice(
              this.curGeneratingFeedIdList.indexOf(video_id),
              1
            );
            delete this.sseGeneratingMap[video_id];
            this.generating = this.getIsGenerating();
          }
        }
      }
    },
  },
});

export default creatVideoResultDataStore;

<template>
  <div class="c-modal" v-if="pageVisible">
    <div class="mask">
      <div class="rights-box">
        <Rights v-if="rightType == 'generate'" :rightType="rightType" />
      </div>
      <div class="content sign-up">
        <div class="mobile-title">
          <div class="left">Sign Up</div>
          <img @click="closePage" src="@/assets/img/mobile/icon-close.png" alt="" />
        </div>
        <div class="close-btn" @click="closePage">
          <img src="@/assets/img/ic_close.png" alt="" />
        </div>
        <div class="flex-center">
          <div class="login-module-main login-in-main">
            <div class="login-img-box">Sign Up</div>
            <div class="login-in-main-content">
              <Regist @submit="submit" @continueWith="continueWith" />
            </div>
            <p class="forget-password-footer tc">
              Already have an account?
              <span @click="gologin" class="a-link btn pointer">Sign in</span>
            </p>
            <div class="terms">
              By continuing, you agree to<a href="/terms" class="a-link btn pointer"> Terms of Use</a> and<a
                href="/privacy"
                class="a-link btn pointer"
              >
                Privacy Policy</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef, getCurrentInstance } from "vue"
import Regist from "@/components/login/RegistForm.vue"
import apiUser from "@/api/api/apiUser.js"
import { useRouter } from "vue-router"
import useUserDataStore from "@/store/userDataStore"
import { funComponentList } from "@/components/functionComp/index"
import message from "@/components/functionCallComponent/message/message.js"
import { getParameterByName } from "@/utils/tools.js"
import Rights from "./rights.vue"
import { getUseSource } from "@/api/net/config.js"


const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  okText: {
    type: String,
    default: "确定",
  },
  handleOk: {
    type: Function, //成功回调
    default: null,
  },
  handleCancel: {
    type: Function, //失败回调
    default: null,
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
  content: {
    type: String,
    default: "自定义全局函数组件......",
  },
  rightType: {
    type: String, // 'generate' | 'login' | undefined, // 显示权益的类型
  },
})

const pageVisible = ref(false)
pageVisible.value = props.visible
// 监听显示的消失，需要移除dom
watch(
  () => pageVisible.value,
  (val) => {
    !val && props.remove()
  }
)

const closePage = () => {
  pageVisible.value = false
}

const gologin = () => {
  pageVisible.value = false
  funComponentList.LogIn({
    // content: "在request.ts触发的函数式组件",
    handleOk: props.handleOk,
    handleCancel: props.handleCancel,
    rightType: props.rightType,
  })
}

// 确认
// const _sure = () => {
//   typeof props.handleOk === "function" && props.handleOk("组件参数")
//   pageVisible.value = false
//   clickOpenFunComponent()
// }
const userDataStore = useUserDataStore()
const Router = useRouter()

const internalInstance = getCurrentInstance()
const globalProperties = internalInstance.appContext.config.globalProperties

const emits = defineEmits([])

const updateAuthData = (_data) => {
  userDataStore.upDataAuthData(_data.auth)
  userDataStore.updateLoginStatus(true)
}
const submit = async ({ username, email, password }) => {
  const { data, code, msg } = await apiUser.userRegister({
    // x_auth_mode: 'otp_email',
    // x_auth_email: email,
    // x_auth_password: password,
    email: email,
    mode: "otp_email",
    password: password,
    username: username,
    source: getUseSource() ?? "",
  })
  if (code === 200) {
    // updateAuthData(data)
    const res = await apiUser.sendEmail(email)
    if (res.code === 200) {
      typeof props.handleOk === "function" && props.handleOk(true)
      message.success({
        maskClosable: true,
        content: "The verification email has been sent, please check it",
        position: "top",
      })
    } else {
      typeof props.handleCancel === "function" && props.handleCancel()
      message.error({
        maskClosable: true,
        content: "Failed to send verification email, please try again later.",
        position: "top",
      })
    }
  } else {
    typeof props.handleCancel === "function" && props.handleCancel()
    message.error({
      maskClosable: true,
      content: msg,
      position: "top",
    })
  }

  // globalProperties.logClickEvent("otp_email", {
  //   code: `${code}`,
  // })
}

const continueWith = async (state) => {
  const { data, code, msg } = await apiUser.userLogin({
    mode: "google",
    token: state.x_auth_token,
    source: getUseSource() ?? "",
  })
  if (code === 200) {
    console.log("第三方登录成功")
    updateAuthData(data)
    typeof props.handleOk === "function" && props.handleOk(true)
    pageVisible.value = false
  } else {
    typeof props.handleCancel === "function" && props.handleCancel()
    message.error({
      maskClosable: true,
      position: "top",
      content: msg,
    })
  }
}
</script>

<style lang="scss">
.c-modal {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  .mask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    .rights-box{
      display: block;
    }
  }
  .content {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    background: linear-gradient(130deg, #1A3C8D 0%, #695BC5 47%, #3A1B6F 100%);
    overflow: hidden;
    border-radius: 20px;
    // width: 520px;
    padding: 12px 60px 0;
    .mobile-title {
      display: none;
    }
    .a-link {
      &:hover {
        opacity: 0.8;
      }
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .back-btn {
      position: absolute;
      left: 24px;
      top: 24px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .login-img-box {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      line-height: 56px;
      height: 56px;
      text-align: center;
      margin-bottom: 12px;
    }
    .forget-password-footer {
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;
      color: #ffffff;
      margin-bottom: 10px;
      span {
        color: #94adff;
      }
    }
    .terms {
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      align-items: center;
      letter-spacing: 0em;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 30px;
      a {
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mask {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      .rights-box{
        display: none;
      }
      .close-btn {
        display: none;
      }
      .content {
        margin: 0 16px;
        padding: 20px;
        box-sizing: content-box;
        .mobile-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          .left {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
          }
          img {
            width: 40px;
            height: 40px;
          }
        }

        .close-btn {
          display: none;
        }

        .login-img-box {
          display: none;
        }
        .terms {
          margin-bottom: 5px;
        }
      }
    }
  }
}

</style>

<template>
  <div class="audio-clipping-fixed-duration" :style="renderStyle">
    <div class="acfd-clip-head flex">
      <div class="acfd-clip-title">{{ props.label }}</div>
      <div class="acfd-clip-time flex1 width-0 tr">
        <div class="acfd-clip-info-time">
          <span class="acfd-clip-start-time">
            {{ mediaUtil.formatTime(renderPlayerData.startTime) }}
          </span>
          <span class="acfd-clip-time-decollator"> / </span>
          <span class="acfd-clip-end-time">
            {{ mediaUtil.formatTime(renderPlayerData.totalDuration) }}
          </span>
        </div>
      </div>
    </div>
    <div class="acfd-clip-main flex">
      <div class="acfd-clip-op flex-center">
        <LoaderProgressCircle
          :size="40"
          default-color="rgba(255,255,255,0.75)"
          progress-color="#94ADFF"
          :progress="0"
        >
          <div
            class="acfd-clip-control flex-center pointer"
            @click="doTogglePlay"
          >
            <i v-if="!renderPlayerData.isPlaying" class="block play"></i>
            <i class="block pause" v-if="renderPlayerData.isPlaying"></i>
            <!-- <i class="block rotate-loading" v-if="renderData.isplay && renderData.buffer"></i> -->
          </div>
        </LoaderProgressCircle>
      </div>
      <div class="acfd-clip-area flex1 width-0">
        <div class="acfd-clip-area-bg height-all">
          <div class="acfd-clip-area-mask height-all"></div>
        </div>
        <div class="acfd-clip-area-wave-box height-all">
          <div class="acfd-clip-wavebar height-all">
            <div class="acfd-clip-wavebar-render-box flex">
              <AudioWaveRender
                v-if="renderData.ifRender"
                :renderBoxSize="renderData.renderBoxSize"
                :config="{
                  spacingSize: props.config.spacingSize,
                  wavebarWidth: props.config.wavebarWidth,
                }"
                :waveBarData="renderData.renderWavebarData"
                :activeIndex="renderData.maxIndex"
                :activeMinIndex="renderData.minIndex"
              ></AudioWaveRender>
              <!-- <template
                v-for="(item, index) in renderData.renderWavebarData"
                :key="`${index}__${item}`"
              >
                <div
                  class="acfd-clip-wavebar-item"
                  :style="{
                    height: item,
                    background:
                      index >= renderData.minIndex &&
                      index < renderData.maxIndex
                        ? '#72FFEC'
                        : 'rgba(255, 255, 255, 0.85)',
                  }"
                ></div>
              </template> -->
            </div>
          </div>
        </div>
        <div
          class="acfd-clip-fixed-area-mask height-all pointer"
          ref="refClipFixedBox"
        >
          <div class="acfd-progress-line-box height-all">
            <div class="acfd-progress-line height-all"></div>
            <div
              class="acfd-progress-drag-area height-all"
              ref="refProgressLineBox"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="acfd-clip-info flex">
      <div class="acfd-clip-info-time">
        <!-- <span class="acfd-clip-start-time">
          {{ mediaUtil.formatTime(renderPlayerData.startTime) }}
        </span>
        <span class="acfd-clip-time-decollator"> / </span>
        <span class="acfd-clip-end-time">
          {{ mediaUtil.formatTime(renderPlayerData.totalDuration) }}
        </span> -->
      </div>
      <div class="acfd-clip-info-duration">
        {{
          props.selectResultText.replace(
            "[selected_duration]",
            Math.floor(renderPlayerData.duration)
          )
        }}
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
  onBeforeUnmount,
} from "vue";
import LoaderProgressCircle from "@/components/animation/LoaderProgressCircle.vue";

import mediaUtil from "../utils/utils.js";
import globalAudioPlayer from "../audioPlayer/GlobalAudioPlayer.js";
const audioUrl =
  "https://test.mureka.ai/oss/aiprod/editor_generate/lyrics_to_song/2990/7582/music_MDoyOjI5OTA6MToxNjY5NjozMDcxODA3MDM4NzAzNTM0MDk=_7582_SoACeMOkmz.wav";
import tools from "@/utils/tools.js";
import clipUtil from "../utils/clipUtil.js";
import AudioWaveRender from "../audioPlayer/AudioWaveRender.vue";
const props = defineProps({
  config: {
    type: Object,
    default: {
      spacingSize: 3,
      wavebarWidth: 1,
      clipDuration: 30,
      tbPadding: 10,
    },
  },
  lrPaddingSize: {
    type: Number,
    default: 20,
  },
  label: {
    type: String,
    default: "Select clip",
  },
  selectResultText: {
    type: String,
    default: "Selected [selected_duration] seconds of audio",
  },

  clipSourceData: {
    type: Object, // decode
    default: null,
    // {
    //    blobUrl: String
    //    type: String
    //    decodeData: AudioBuffer,
    // }
  },
});
const emits = defineEmits([]);

let statusData = {
  renderWavebarReady: false,
  touchSwipeState: false,
  touchSwipeLineState: false,
};

const renderDefaultData = {
  clipAreaWidth: 0,
  waveBarBoxRenderWidth: 0,
  renderWavebarData: [],
  translateX: 0,
  enabledClip: false,
  minIndex: 0,
  maxIndex: 0,
};
const renderData = reactive({
  ...renderDefaultData,
  ifRender: true,
});

const renderPlayerDefaultData = {
  decodeData: null,
  totalDuration: 0,
  isPlaying: false,
  startTime: 0,
  endTime: 0,
  currentTime: 0,
  progress: 0,
  duration: 0,
  translateLineX: 0,
  renderPlayerData: null,
};
const renderPlayerData = reactive({
  ...renderPlayerDefaultData,
  // clipTimeInfo:{
  //   startTime: 0,
  //   endTime: 0,
  //   progress: 0,
  //   duration: 0,
  // }
});

const resetData = () => {
  statusData = {
    renderWavebarReady: false,
    touchSwipeState: false,
    touchSwipeLineState: false,
  };
  Object.assign(renderPlayerData, renderPlayerDefaultData);
  Object.assign(renderData, renderDefaultData);
};

const renderStyle = computed(() => {
  return {
    "--paddinglr": `${props.lrPaddingSize}px`,
    "--waveBarBoxRenderWidth": `${renderData.waveBarBoxRenderWidth}px`,
    "--translateX": `${renderData.translateX}px`,
    "--wavebarWidth": `${props.config.wavebarWidth}px`,
    "--wavebarLrMargin": `0 ${props.config.spacingSize / 2}px`,
    "--translateLineX": `${renderPlayerData.translateLineX}px`,
  };
});

const refClipFixedBox = ref(null);
const refProgressLineBox = ref(null);

const getClipTimeInfo = (toatalDuration) => {
  const {
    renderWavebarData,
    waveBarBoxRenderWidth,
    translateX,
    clipAreaWidth,
  } = renderData;
  const { translateLineX } = renderPlayerData;
  let startTime = 0;
  let endTime = 0;
  let progress = 0;
  if (renderData.enabledClip) {
    startTime = Math.floor(
      (Math.abs(translateX) / waveBarBoxRenderWidth) * toatalDuration
    );
    // endTime = Math.ceil((Math.abs(translateX) + clipAreaWidth) / waveBarBoxRenderWidth * toatalDuration);
    endTime = startTime + props.config.clipDuration;
    progress = translateLineX / clipAreaWidth;
  } else {
    startTime = 0;
    endTime = toatalDuration;
    progress = translateLineX / waveBarBoxRenderWidth;
  }
  return {
    startTime,
    endTime,
    progress,
    duration: endTime - startTime,
  };
};

const updateClipTimeInfo = (isSeek) => {
  const { startTime, endTime, progress, duration } = getClipTimeInfo(
    renderPlayerData.totalDuration
  );
  renderPlayerData.startTime = startTime;
  renderPlayerData.endTime = endTime;
  renderPlayerData.duration = duration;
  renderPlayerData.progress = progress;
  if (!isSeek) {
    // doSetPlayeData()
    doSetClipData(startTime, endTime);
  } else {
    doSeek(progress);
  }
};
const doSetClipData = (startTime, endTime) => {
  globalAudioPlayer.setClip(
    globalAudioPlayerData.curPlayerData?.url,
    startTime,
    endTime
  );
  nextTick().then(() => {
    globalAudioPlayer.play(globalAudioPlayerData.curPlayerData.url, false);
  });
};

const doSeek = async (progress) => {
  renderPlayerData.progress = progress;
  const {
    enabledClip,
    renderWavebarData,
    waveBarBoxRenderWidth,
    translateX,
    clipAreaWidth,
  } = renderData;
  renderPlayerData.translateLineX = enabledClip
    ? clipAreaWidth * progress
    : waveBarBoxRenderWidth * progress;

  globalAudioPlayer.seek(globalAudioPlayerData.curPlayerData.url, progress);
};

const globalAudioPlayerData = {
  curPlayerData: null,
};
const doSetPlayeData = async () => {
  console.log("set播放路径，gogogo");
  // globalAudioPlayerData.clipDecodeData = await clipUtil.clipdDecodeData(statusData.decodeData, renderPlayerData.startTime, renderPlayerData.endTime)
  // const exportData = await clipUtil.decodeDataToUrl(statusData.decodeData);
  const url = props.clipSourceData.url ?? props.clipSourceData.blobUrl;
  if (globalAudioPlayerData.curPlayerData !== null) {
    globalAudioPlayer.releasePlayer(globalAudioPlayerData.curPlayerData);
  }

  globalAudioPlayerData.curPlayerData = globalAudioPlayer.registerPlayUrl(url, {
    duration: renderPlayerData.totalDuration ?? 0,
    listeners: {
      onDurationChange({
        duration,
        durationTime,
        sourceDuration,
        sourceDurationTime,
      }) {
        renderPlayerData.duration = duration;
        renderData.endTime = durationTime;
      },
      onPlayStateChange(isPlaying) {
        // console.log("onPlayStateChange", isPlaying)
        renderPlayerData.isPlaying = isPlaying;
      },
      onLoadingStateChange(isLoading) {
        // renderData.isLoading = isLoading;
      },
      onPositionChange: (obj) => {
        // console.log("onPositionChange", obj)
        const { progress, currentTime, endTime } = obj;
        // renderData.startTime = startTime;
        renderPlayerData.progress = progress;
        const {
          enabledClip,
          renderWavebarData,
          waveBarBoxRenderWidth,
          translateX,
          clipAreaWidth,
        } = renderData;
        renderPlayerData.translateLineX = enabledClip
          ? clipAreaWidth * progress
          : waveBarBoxRenderWidth * progress;
        setIsHighLight();
      },
    },
  });
  globalAudioPlayer.play(globalAudioPlayerData.curPlayerData.url, false);
};

const doPlay = async () => {
  const { renderWavebarReady, touchSwipeState, touchSwipeLineState } =
    statusData;
  if (!renderWavebarReady) {
    return;
  }
  if (touchSwipeState || touchSwipeLineState) {
    return;
  } else {
    // 可以播放了，go
    console.log("开始播放，gogogo");
    globalAudioPlayer.play(globalAudioPlayerData.curPlayerData.url, false);
  }
};

const doPause = async () => {
  if (globalAudioPlayerData?.curPlayerData?.url) {
    globalAudioPlayer.pause(globalAudioPlayerData.curPlayerData.url);
  }
  console.log("暂停播放，gogogo");
};

const doTogglePlay = async () => {
  if (renderPlayerData.isPlaying) {
    await doPause();
  } else {
    await doPlay();
  }
};

const setIsHighLight = () => {
  // const { startTime, endTime, progress, duration } = getClipTimeInfo(renderPlayerData.totalDuration);
  // const { renderWavebarData, waveBarBoxRenderWidth, translateX, clipAreaWidth } = renderData;
  // const minIndex = Math.ceil(startTime/renderPlayerData.totalDuration * renderWavebarData.length);
  // const maxIndex = Math.floor((startTime + duration * progress) / renderPlayerData.totalDuration * renderWavebarData.length);
  // return index >= minIndex && index <= maxIndex;

  const {
    renderWavebarData,
    waveBarBoxRenderWidth,
    translateX,
    clipAreaWidth,
  } = renderData;
  const { translateLineX } = renderPlayerData;
  const minIndex = Math.ceil(
    (Math.abs(translateX) / waveBarBoxRenderWidth) * renderWavebarData.length
  );
  const maxIndex = Math.ceil(
    ((Math.abs(translateX) + translateLineX) / waveBarBoxRenderWidth) *
      renderWavebarData.length
  );
  renderData.minIndex = minIndex;
  renderData.maxIndex = maxIndex;
};

const initEvent = () => {
  statusData.touchSwipeState = false;
  statusData.touchSwipeLineState = false;
  let startTranslateX = 0;
  let startTranslateLineX = 0;
  statusData.registTouchWaveBarSwipeCall = tools.registTouchSwipe(
    refClipFixedBox.value,
    {
      direction: "horizontal",
      startCb: (e, actionData) => {
        if (renderData.enabledClip && !statusData.touchSwipeLineState) {
          statusData.touchSwipeState = true;
          startTranslateX = renderData.translateX;
        }
      },
      moveCb: (e, actionData) => {
        if (statusData.touchSwipeState) {
          const { startX, endX } = actionData;
          const dx = Math.floor(endX - startX);
          renderData.translateX = startTranslateX + dx;
          // 防止超出边界
          if (renderData.translateX > 0) {
            renderData.translateX = 0;
          } else if (
            renderData.translateX <
            renderData.clipAreaWidth - renderData.waveBarBoxRenderWidth
          ) {
            renderData.translateX =
              renderData.clipAreaWidth - renderData.waveBarBoxRenderWidth;
          } else {
          }
        }
      },
      endCb: (e, actionData) => {
        if (statusData.touchSwipeState) {
          statusData.touchSwipeState = false;
          updateClipTimeInfo(false);
          setIsHighLight();
        }
      },
    }
  );
  // const registTouchSwipeProgressLineCall = tools.registTouchSwipe(
  //   refProgressLineBox.value,
  //   {
  //     direction:'horizontal',
  //     startCb:((e, actionData) => {
  //       if(!statusData.touchSwipeState){
  //         statusData.touchSwipeLineState = true;
  //         startTranslateLineX = renderPlayerData.translateLineX;
  //       }
  //     }),
  //     moveCb:((e, actionData) => {
  //       if(statusData.touchSwipeLineState){
  //         const {startX, endX} = actionData;
  //         const dx = Math.floor(endX - startX);
  //         renderPlayerData.translateLineX = startTranslateLineX + dx;
  //         const {clipAreaWidth, waveBarBoxRenderWidth} = renderData;
  //         // 防止超出边界
  //         if(renderPlayerData.translateLineX > (waveBarBoxRenderWidth >= clipAreaWidth ? clipAreaWidth : waveBarBoxRenderWidth)){
  //           renderPlayerData.translateLineX = waveBarBoxRenderWidth >= clipAreaWidth ? clipAreaWidth : waveBarBoxRenderWidth;
  //         }else if(renderPlayerData.translateLineX < 0){
  //           renderPlayerData.translateLineX = 0
  //         }else{

  //         }
  //       }
  //     }),
  //     endCb:((e, actionData) => {
  //       if(statusData.touchSwipeLineState){
  //         statusData.touchSwipeLineState = false;
  //         updateClipTimeInfo(true);
  //       }
  //     }),
  //   }
  // );
};

const init = async (decodeData) => {
  // const  decodeData = await clipUtil.getDecodeDataByFile(renderPlayerData.file);
  if (!refClipFixedBox.value) {
    return;
  }
  let { width, height } = refClipFixedBox.value.getBoundingClientRect();
  renderPlayerData.sourceDecodeData = decodeData;
  const { duration } = decodeData;
  renderPlayerData.totalDuration = duration;
  const { clipDuration, spacingSize, wavebarWidth, tbPadding } = props.config;
  renderData.clipAreaWidth = width;
  if (clipDuration < duration) {
    renderData.enabledClip = true;
    renderData.waveBarBoxRenderWidth = Math.ceil(
      width * (duration / clipDuration) - spacingSize
    );

    renderPlayerData.duration = clipDuration;
    renderPlayerData.startTime = 0;
    renderPlayerData.endTime = clipDuration;
  } else {
    renderData.enabledClip = false;
    renderData.waveBarBoxRenderWidth =
      Math.ceil(width * (duration / clipDuration)) - spacingSize;

    renderPlayerData.duration = duration;
    renderPlayerData.startTime = 0;
    renderPlayerData.endTime = duration;
  }
  renderData.renderBoxSize = {
    width: renderData.waveBarBoxRenderWidth,
    height: height,
  };

  renderPlayerData.progress = 0;
  const waveBarCount = Math.ceil(
    renderData.waveBarBoxRenderWidth / (spacingSize + wavebarWidth)
  );
  const wavebarData = clipUtil.getWavebarData(decodeData, waveBarCount);
  const harfheight = Math.floor(height - tbPadding * 2);
  const wavebarPainterData = clipUtil.fixedPaintHarfHeightWaveBarData(
    wavebarData,
    harfheight
  );
  renderData.renderWavebarData = wavebarPainterData;
  nextTick(async () => {
    await doSetPlayeData();
    if (renderData.enabledClip) {
      updateClipTimeInfo(false);
      setIsHighLight();
    }
    statusData.renderWavebarReady = true;
  });
};

// const renderWaveBar = (sourceDecodeData) => {
//   let {width, height} = refClipFixedBox.value.getBoundingClientRect();
//   const  decodeData = sourceDecodeData ?? renderPlayerData.sourceDecodeData;
//   const { duration } = decodeData;
//   const { clipDuration, spacingSize, wavebarWidth, tbPadding  } = props.config;
//   renderData.clipAreaWidth = width;
//   if(clipDuration < duration){
//     renderData.waveBarBoxRenderWidth = Math.ceil(width * (duration / clipDuration));
//   }else{
//     width = duration / clipDuration * width;
//     renderData.waveBarBoxRenderWidth = Math.ceil(width * (duration / clipDuration));
//   }
//   const waveBarCount = renderData.waveBarBoxRenderWidth / (spacingSize + wavebarWidth);
//   const wavebarData = clipUtil.getWavebarData(decodeData, waveBarCount);
//   const harfheight = Math.floor((height-tbPadding * 2) / 2);
//   const wavebarPainterData = clipUtil.fixedPaintHarfHeightWaveBarData(wavebarData, harfheight);
//   renderData.renderWavebarData = wavebarPainterData;
// }

// const onResize = () => {
//   let {width, height} = refClipFixedBox.value.getBoundingClientRect();
//   // const scaleXRadio = renderData.clipAreaWidth/width;
//   const { startTime, endTime, progress, duration } = renderPlayerData.clipTimeInfo;
//   renderWaveBar();
//   const { renderWavebarData, waveBarBoxRenderWidth, translateX, clipAreaWidth } = renderData;
//   if(renderData.enabledClip){
//     renderPlayerData.translateLineX = progress * clipAreaWidth;
//   }else{
//     renderPlayerData.translateLineX = progress * waveBarBoxRenderWidth;
//   }
// }

// const initResizeObserve = () => {
//   const resizeObserver = new ResizeObserver(()=>{
//     nextTick(()=>{
//       onResize()
//     })
//   });
//   resizeObserver.observe(refClipFixedBox.value);
// }

const initClipRender = () => {
  if (statusData.clipSourceData) {
    nextTick().then(() => {
      setTimeout(async () => {
        init(statusData.clipSourceData.decodeData);
        renderData.ifRender = false;
        nextTick().then(() => {
          renderData.ifRender = true;
        });
      }, 20);
    });
  }
};

watch(
  () => {
    return props.clipSourceData;
  },
  () => {
    resetData();
    statusData.clipSourceData = props.clipSourceData;
    if (statusData.clipSourceData != null) {
      initClipRender();
    }
  }
);
onMounted(() => {
  resetData();
  statusData.clipSourceData = props.clipSourceData;
  initClipRender();
  nextTick().then(() => {
    initEvent();
  });
  // initResizeObserve();
});
watch(
  () => {},
  () => {}
);
onBeforeUnmount(() => {
  statusData.registTouchWaveBarSwipeCall?.();
});
defineExpose({
  pause() {
    doPause();
  },
  getClipedExportData: async () => {
    console.log(
      "getClipedExportData",
      renderPlayerData.startTime,
      renderPlayerData.endTime
    );
    const clipDecodeData = await clipUtil.clipdDecodeData(
      statusData.clipSourceData.decodeData,
      renderPlayerData.startTime,
      renderPlayerData.endTime
    );
    const exportData = await clipUtil.decodeDataToUrl(
      clipDecodeData,
      statusData.clipSourceData.type
    );
    return exportData;
  },
  validate: async () => {
    return statusData.renderWavebarReady;
  },
});
</script>
<style lang="scss">
.audio-clipping-fixed-duration {
  padding: 0 var(--paddinglr) 0 0;
  .acfd-clip-head {
    padding: 0 0 0 var(--paddinglr);
    height: 24px;
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 8px;
    .acfd-clip-time {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.75);
    }
    .acfd-clip-start-time {
      color: #fff;
    }
  }
  .acfd-clip-main {
    margin-top: 8px;
    align-items: center;
  }
  .acfd-clip-op {
    height: 40px;
    width: 40px;
    margin: 0 8px 0 var(--paddinglr);
  }
  .acfd-clip-control {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 48px 48px 48px 48px;
    // border: 2px solid rgba(255, 255, 255, 1);

    i {
      height: 16px;
      width: 16px;
    }
    i.play {
      background: url("@/assets/img/play-light-ico.png");
      background-size: 100% 100%;
    }
    i.pause {
      background: url("@/assets/img/pause-light-ico.png");
      background-size: 100% 100%;
    }
  }
  .acfd-clip-area {
    height: 56px;
    position: relative;
    overflow: hidden;
  }
  .acfd-clip-area-bg {
    background: rgba(255, 255, 255, 0.1);
    .acfd-clip-area-mask {
      position: absolute;
      top: 0;
      left: var(--paddinglr);
      bottom: 0;
      right: var(--paddinglr);
      background: rgba(148, 173, 255, 0.2);
      /*background: rgba(148, 173, 255, 0.15);*/
    }
  }
  .acfd-clip-area-wave-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .acfd-clip-wavebar {
      position: relative;
      padding: 0 var(--paddinglr) 0 var(--paddinglr);
    }

    .acfd-clip-wavebar-render-box {
      width: var(--waveBarBoxRenderWidth);
      height: 100%;
      align-items: center;
      justify-content: start;
      transform: translateX(var(--translateX));
    }
    .acfd-clip-wavebar-item {
      width: var(--wavebarWidth);
      border-radius: var(--wavebarWidth);
      margin: var(--wavebarLrMargin);
    }
    .acfd-clip-wavebar-item:first-child {
      margin-left: 0;
    }
  }
  .acfd-clip-fixed-area-mask {
    position: absolute;
    top: 0;
    left: var(--paddinglr);
    right: var(--paddinglr);
  }
  .acfd-progress-line-box {
    width: 1px;
    position: relative;
    /*transform: translateX(var(--translateLineX));*/
    .acfd-progress-line {
      position: absolute;
      top: 0;
      left: -1px;
      background: #fff;
      width: 2px;
      border-radius: 3px;
    }
    .acfd-progress-drag-area {
      position: absolute;
      width: 21px;
      top: 0;
      left: -10px;
    }
  }

  .acfd-clip-info {
    padding: 8px 0 0 var(--paddinglr);
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    line-height: 16px;
    justify-content: space-between;
    align-items: center;
    .acfd-clip-start-time {
      color: #000818;
    }
  }
}
</style>

import axiosIS from "@/api/net/axios.js"
import ApiConfig, { baseUrl } from "@/config/apiConfig.js"
export default {
  // SVC-web邮箱注册
  // https://rg975ojk5z.feishu.cn/docx/Zct5d1uKsosaLoxhT0qcbHownpc
  userRegister(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.signUp}`, data, { method: "post" })
  },

  // SVC-邮箱登录(三方注册登录)
  // https://rg975ojk5z.feishu.cn/docx/Yrgrd964roO1cDxHgz8cc4HcnBh
  userLogin(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.loginIn}`, data, { method: "post" })
  },

  // SVC-邮箱注册忘记密码，服务端校验发邮件
  // https://rg975ojk5z.feishu.cn/docx/VqWHdQZqKo3gqfxyK1MctNTJnv7
  userSendEmailToRetrievePassword(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.forgetPassword}`, data, { method: "post" })
  },

  // SVC-忘记密码后重置密码
  // https://rg975ojk5z.feishu.cn/docx/TpFydOEQLo8uzZxz8EocGGk5n6f
  userResetPassword(data = {}) {
    return axiosIS(
      `${baseUrl}${ApiConfig.resetPasswordAuth}`,
      // /api/web/password 新接口后续换吧
      data,
      { method: "put" }
    )
  },

  // SVC-邮箱用户登录后正常修改密码操作
  // https://rg975ojk5z.feishu.cn/docx/Ele2dWqgEoki2Ux0Ub9cz8H5nSh
  userModifyPassword(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.resetPassword}`, data, { method: "post" })
  },
  // 邀请码
  userInvite(data = {}) {
    return axiosIS(`${baseUrl}/api/user/invite`, data, { method: "post" })
  },
  // SVC-登出
  //   https://rg975ojk5z.feishu.cn/docx/DhN9d9zl1ojeryx2B6rcTay3ncd
  userLoginOut(data = {}, user_id) {
    return axiosIS(`${baseUrl}${ApiConfig.loginOut}/${user_id}`, data, { method: "post" })
  },
  // SVC-个人用户基本信息
  // https://rg975ojk5z.feishu.cn/docx/CZ9Wd29fRoxCVHxf6d9cbkrGnhc
  userGetInfo() {
    return axiosIS(`${baseUrl}${ApiConfig.getUserInfo}`, {}, { method: "get" })
  },
  getBanerConfig(){
    return axiosIS(`${baseUrl}${ApiConfig.getBanerConfig}`, {}, { method: "post" })
  },
  // 埋点上报
  userPoint() {
    return axiosIS(`${baseUrl}${ApiConfig.sendPoint}`, {}, { method: "get" })
  },
  // SVC-个人用户基本信息更新
  // https://rg975ojk5z.feishu.cn/docx/A6RIdAv5HofbOXxV3pxcSxKNn5f
  updateUserInfo(data) {
    return axiosIS(`${baseUrl}/api/user/profile/update`, data, { method: "post" })
  },
  // SVC-个人用户基本信息更新
  // https://rg975ojk5z.feishu.cn/docx/A6RIdAv5HofbOXxV3pxcSxKNn5f
  sendEmail(email) {
    return axiosIS(`${baseUrl}${ApiConfig.sendEmail}?email=${email}`, {}, { method: "get" })
  },
  userConfig() {
    return axiosIS(`${baseUrl}${ApiConfig.getConfig}`, {}, { method: "get" })
  },
  switchLanguage(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.language}`, data, { method: "post" })
  },
  // 用户注销
  deleteAccount(data = {}) {
    return axiosIS(`${baseUrl}/api/user/account/delete`, data, { method: "post" })
  },
  // 获取验证码
  getVerifyCode(data = {}) {
    return axiosIS(`${baseUrl}/api/user/login/otp/message`, data, { method: "post" })
  },
  userLoginCn(data = {}) {
    return axiosIS(`${baseUrl}/api/user/login`, data, { method: "post" })
  },
  userCodePeport(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/channel/code/check`, data, { method: "post" })
  },
  inviteCodePoint(data = {}){
    return axiosIS(`${baseUrl}/api/pgc/invite/register_report`, data, { method: "post" })
  },
  // 邮件召回
  giveMailRecallCredits(data = {}){
    return axiosIS(`${baseUrl}/api/pgc/mail-recall/give-credits`, data, { method: "post" })
  },
  userPaySuccess(data = {}){
    return axiosIS(`${baseUrl}/api/payment/issue_report`, data, { method: "post" })
  }
}
<template>
  <div
    v-if="popupVisible"
    :class="[
      'video-result',
      `video-result-${type}`,
      'flex-row-center',
      'pointer',
      `video-result-${!!curPlayData}`,
    ]"
    @click="goResult"
  >
    <div>
      {{
        type == "success"
          ? "Your music video is ready. Click to view!"
          : "Your music video generation failed. Please try again"
      }}
    </div>
    <img
      v-if="type == 'success'"
      src="@/assets/img/activity/arrow-right.png"
      alt=""
    />
    <img v-else src="@/assets/img/activity/arrow-right-fail.png" alt="" />
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router/index.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";

const { curPlayData } = storeToRefs(useGlobalPlayDataStore());
const { createRenderType } = storeToRefs(useCreateLayoutStore());

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "success",
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
});

const popupVisible = ref(props.visible);

watch(
  () => createRenderType.value,
  (val) => {
    if (val == "mobile") {
      popupVisible.value = false;
    }
  }
);

const goResult = () => {
  popupVisible.value = false;
  firebaseUtils.logClickEvent("activity_alert", {
    activity_name: 'christmas_lip'
  });
  router.push({
    name: "createVideo",
    query: {
      form: 'tip'
    }
  });
};
</script>

<style lang="scss" scoped>
@keyframes reveal {
  0% {
    right: -100px; /* 起始位置 */
    opacity: 0;
  }
  100% {
    right: 16px; /* 终止位置 */
    opacity: 1;
  }
}

.video-result {
  width: max-content;
  position: fixed;
  font-size: 14px;
  line-height: 16px;
  padding: 13px 32px 13px 12px;
  border-radius: 11px;
  right: -100px; /* 最开始不可见的位置 */
  transform: translate(0, -50%);
  animation: reveal 0.3s forwards;
  img {
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
  &-success {
    background: #72ffec;
    color: #000;
  }
  &-fail {
    background: #fff3ec;
    color: #f40021;
  }
}

.video-result-true {
  bottom: 82px;
}

.video-result-false {
  bottom: 16px;
}
</style>
import axiosIS from "@/api/net/axios.js";
import ApiConfig, { baseUrl } from "@/config/apiConfig.js";
export default {
  getAllReferMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/refer/list/library`, data, {
      method: "get",
    });
  },
  getUsedReferMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/refer/list/used`, data, {
      method: "get",
    });
  },
  getMineReferMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/refer/list/mine`, data, {
      method: "get",
    });
  },
  addReferenceMusic(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/refer/add`, data, {
      method: "post",
      ...options,
    });
  },

  saveReferenceMusic(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/audio-control/apply`, data, {
      method: "post",
      ...options,
    });
  },
  deleteReferenceMusic(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/audio-control/remove`, data, {
      method: "post",
      ...options,
    });
  },

  deleteReferMusicItem(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/refer/delete`, data, {
      method: "post",
      ...options,
    });
  },
  saveReferItemTitle(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/refer/rename`, data, {
      method: "post",
      ...options,
    });
  },
  addMotifMusic(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/motif/add`, data, {
      method: "post",
      ...options,
    });
  },

  addVocalMusic(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/vocal/add`, data, {
      method: "post",
      ...options,
    });
  },
  deleteVocalMusicItem(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/motif/add`, data, {
      method: "post",
      ...options,
    });
  },
  saveEditVocalMusicItemInfo(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/motif/add`, data, {
      method: "post",
      ...options,
    });
  },

  getAllVocalMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/vocal/list/library`, data, {
      method: "get",
    });
  },
  getUsedVocalMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/vocal/list/used`, data, {
      method: "get",
    });
  },
  getMineVocalMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/vocal/list/mine`, data, {
      method: "get",
    });
  },
  deleteVocalMusicItem(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/vocal/delete`, data, {
      method: "post",
      ...options,
    });
  },
  saveVocalItemTitle(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/vocal/rename`, data, {
      method: "post",
      ...options,
    });
  },

  getEasyStyleMoods(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/tags/v2`, data, {
      method: "get",
      ...options,
    });
  },

  getPromptTypeTags(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/prompt/tags/v2`, data, {
      method: "get",
      ...options,
    })
  },
  getAllMotifMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/motif/list/library`, data, {
      method: "get",
    });
  },
  getUsedMotifMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/motif/list/used`, data, {
      method: "get",
    });
  },
  getMineMotifMusics(data = {}, url) {
    return axiosIS(url ?? `${baseUrl}/api/pgc/motif/list/mine`, data, {
      method: "get",
    });
  },

  saveMotifItemTitle(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/motif/rename`, data, {
      method: "post",
    })
  },
  deleteMotifMusicItem(data = {}, options = {}) {
    return axiosIS(`${baseUrl}/api/pgc/motif/delete`, data, {
      method: "post",
    })
  }

};



import { defineStore } from "pinia";
import { showWindowPopup } from "@/api/api/apiCommon.js"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js"
import useUserDataStore from "@/store/userDataStore.js";
import router from "../router";


const useDialogStore = defineStore({
    id: "dialogStore",
    state: () => {
        return {
            isLoading: false,
            last_popup_type: '',
            inviteFinishedDialog: false, // 邀请成功结果弹窗
            recallMailDialog: false, // 召回弹窗
            vocalDialog: false, // vocal弹窗
            advGuideDialog: false, // adv引导弹窗
            whatsNewDialog: false // whats news弹窗
        };
    },
    actions: {
        clearAdvGuideDialog() {
            this.advGuideDialog = false
        },
        closeWhatsNewsDialog() {
            this.whatsNewDialog = false
        },
        openWhatsNewsDialog() {
            this.whatsNewDialog = true
        },
        // 清空用户上次弹窗类型
        clearLastPopupType() {
            this.last_popup_type = ''
        },
        // 更新用户上次弹窗类型
        updateLastPopupType(type) {
            this.last_popup_type = type
        },
        // 检测邮件召回弹窗是否弹出
        getPopupShow() {
            console.log( this.getPopupShow,router.currentRoute.value, "router.currentRoute.value")
            this.isLoading = true
            const createLayoutStore = useCreateLayoutStore()
            showWindowPopup({
                from_recall_link: !!router.currentRoute.value?.query?.mail_recall_key,
                from_adv_tab: router.currentRoute.value?.name == 'create' ? createLayoutStore.createEditMode == 'hard' ? true : false : false,
                last_popup_type: this.last_popup_type
            }).then(res => {
                if (res.code == 200) {
                    this.isLoading = false
                    console.log(res?.data.popup_window_type, "popup_window_type")
                    this.updateLastPopupType(res?.data?.popup_window_type)
                    const userDataStore = useUserDataStore()
                    switch (res?.data?.popup_window_type) {
                        case 1:
                            this.recallMailDialog = true
                            userDataStore.refreshUserAccount();
                            break;
                        case 2:
                            this.advGuideDialog = true
                            const flag = createLayoutStore.showCreateEditGuidTourControlVisible()
                            if (!flag && userDataStore.isLogin) {
                                this.getPopupShow()
                            }
                            break;
                        case 3:
                            this.inviteFinishedDialog = true
                            break;
                        case 4:
                            this.vocalDialog = true
                            break;
                        case 5:
                            this.whatsNewDialog = true
                            break;
                    }
                }
            })
        }
    }
})


export default useDialogStore;
import axiosIS from "@/api/net/axios.js";
import ApiConfig, { baseUrl } from "@/config/apiConfig.js";
import useUserDataStore from "@/store/userDataStore.js";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import { isProd } from "@/utils/tools.js";


export default {
  getVideoFeedList(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/s2lip-video/list`,
      data,
      { method: "get" }
    );
  },

  // video 详情
  getVideoDetail(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/s2lip-video/detail`,
      data,
      { method: "get" }
    );
  },

  
  mp4Download(data = {}, url) {
    return axiosIS(
      `${baseUrl}/api/pgc/s2lip-video/download`,
      data,
      { method: "post" }
    );
  },

  // https://rg975ojk5z.feishu.cn/wiki/Ogzuw3gWGipIKEkGEQWcpDPcnEg
  doGenerateVideo(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/s2lip-video/generate`, data, {
      method: "post",
    });
  },

  doReGenerateVideo(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/s2lip-video/generate/retry`, data, {
      method: "post", 
    })
  },

  doCancelGenerateVideo(data = {}) {
    return axiosIS(`${baseUrl}/api/pgc/s2lip-video/generate/cancel`, data, {
      method: "post",
    })
  }

};

<template>
  <div
    class="custom-message"
    @click="onMaskClick"
    v-if="renderData.ifRender"
    ref="refCustomMessage"
    :class="`${createRenderType}-custom-message render-position-${props.position} render-type-${props.type}  render-closable-${props.closable} ${props.customClass}`"
  >
    <div class="custom-message-content flex-center" @click.stop>
      <div
        class="custom-message-content-icon"
        @click="onClickClose"
        v-if="props.type === 'loading' || props.closable"
      >
        <div class="custom-message-content-close" v-if="props.closable">
          <i class="block"></i>
        </div>
        <LoaderCircle
          v-if="props.type === 'loading'"
          :loading="true"
          :size="24"
          :strokeWidth="2.8"
          color="#94ADFF"
        />
      </div>
      <div
        class="custom-message-icon"
        :class="`custom-message-${props.type}-ico-box`"
        v-show="props.type == 'success' || props.type == 'warning'"
      >
        <div :class="`custom-message-${props.type}-ico`"></div>
      </div>
      <div class="custom-message-text tl">{{ props.content }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import LoaderCircle from "@/components/animation/LoaderCircle.vue";

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const emits = defineEmits(["update:visible"]);
const props = defineProps({
  customClass: {
    type: String,
    default: "",
  },
  position: {
    type: String,
    default: "center", // center, top, bottom
  },
  type: {
    type: String,
    default: "info", // success warning info error loading
  },
  visible: {
    type: Boolean,
    default: true,
  },
  content: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 3000,
  },
  closable: {
    type: Boolean,
    default: false,
  },
  forbidClick: {
    type: Boolean,
    default: false,
  },
  maskClosable: {
    type: Boolean,
    default: false,
  },
  Click: {
    type: Function,
    default: () => {},
  },
  onClose: {
    type: Function,
    default: () => {},
  },
  beforeClose: {
    type: Function,
    default: null,
  },
});
const refCustomMessage = ref(null);
const renderData = reactive({
  visible: false,
  ifRender: true,
});

const stateData = {
  closeTime: null,
};

const afterShow = () => {
  if (props.duration > 0 && props.type !== "loading") {
    stateData.closeTime = setTimeout(() => {
      close();
    }, props.duration);
  }
};

// 关闭
const close = async () => {
  props.beforeClose && (await props.beforeClose(close));
  renderData.visible = false;
  if (stateData.closeTime != null) {
    clearTimeout(stateData.closeTime);
  }
  stateData.closeTime = null;
};

const onClickClose = () => {
  if (props.closable) {
    close();
  }
};

const onMaskClick = () => {
  if (props.maskClosable) {
    close();
  }
};

watch(
  () => props.visible,
  (newValue, oldValue) => {
    renderData.visible = props.visible;
    if (newValue) {
      afterShow();
    }
  }
);

watch(
  () => renderData.visible,
  (newValue, oldValue) => {
    if (!newValue) {
      props.onClose && props.onClose();
      emits("update:visible", newValue);
    }
  }
);

const reRender = () => {
  renderData.ifRender = false;
  nextTick(() => {
    renderData.ifRender = true;
  });
};

const initResizeObserve = () => {
  if (refCustomMessage.value) {
    const resizeObserver = new ResizeObserver(() => {
      reRender();
    });
    resizeObserver.observe(refCustomMessage.value);
  }
};

onMounted(() => {
  renderData.visible = props.visible;
  // initResizeObserve();
  afterShow();
});
</script>

<style lang="scss">
.custom-message {
  position: fixed;
  z-index: 100;
  transform: translateZ(0);
  overflow: hidden;
  z-index: 100000;
  max-width: 100%;
  padding: 10px 16px;

  .custom-message-content {
    border-radius: 16px;
    padding: 12px 16px;
    align-items: center;
    justify-content: center;
    width: max-content;
    max-width: fit-content;
    background: linear-gradient(99deg, #b3fcff 0%, #e894ff 100%);
  }
  .custom-message-content-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-message-icon {
    margin: 0px 6px 0 -4px;
    div {
      height: 24px;
      width: 24px;
      min-width: 24px;
      min-height: 24px;
      background-size: 100% 100%;
    }
    .custom-message-success-ico {
      background-image: url(@/assets/img/message/success.png);
      background-size: 100% 100%;
    }
    .custom-message-warning-ico {
      background-image: url(@/assets/img/warning.png);
      background-size: 100% 100%;
    }
  }
  .custom-message-text {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    line-height: 24px;
  }

  .custom-message-content-close {
    background-color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(@/assets/img/ic_close_circle_fill.png);
    background-size: 100% 100%;
  }
  &.render-type-loading {
    position: fixed;

    .custom-message-content {
      padding: 24px 24px;
      //background: #545a7d;
      background: rgba(148, 173, 255, 0.3);
      backdrop-filter: blur(8px);
      border: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      margin: 0 auto;

      .custom-message-content-icon {
        height: 24px;
        width: 24px;
        margin: 0 0 12px 0;
      }
      .custom-message-text {
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        color: #ffffff;
        width: max-content;
      }
    }
  }

  &.render-type-success {
    .custom-message-content {
      background: linear-gradient(99deg, #b3fcff 0%, #e894ff 100%);
      backdrop-filter: blur(20px);
    }
  }

  &.render-type-warning {
    .custom-message-content {
      background: linear-gradient(102deg, #ffffff 0%, #d8aaff 100%);
      // background: linear-gradient(102deg, #FFECF3 0%, #FFADCF 100%);
    }
  }

  &.render-closable-true {
    .custom-message-content {
      flex-direction: column;
      .custom-message-content-icon {
        margin: 0 auto 8px auto;
        height: 30px;
        width: 30px;
      }
    }
  }

  &.render-position-top {
    position: fixed;
    top: 0;
    left: unset;
    right: unset;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%) translateY(var(--customTransY));
    background: rgba(0, 0, 0, 0);
  }

  &.render-position-bottom {
    position: fixed;
    top: unset;
    left: unset;
    right: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(var(--customTransY));
    background: rgba(0, 0, 0, 0);
    .custom-message-content {
    }
  }
  &.render-position-center {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;

    .custom-message-content {
      position: relative;
    }
  }
}

.mureka-app {
  .custom-message.render-position-top  {
    width: max-content;
    max-width: 100%;
    .custom-message-content {
      max-width: 100%;
    }
  }
}
</style>

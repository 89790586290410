<template>
  <div>
    <Dialog
      :customClass="`${createRenderType}-invite-dialog`"
      width="707px"
      :height="isSmall ? '420px' : ''"
      :visible="popupVisible"
      background="linear-gradient(147deg, #1A3C8D 0%, #695BC5 47%, #3A1B6F 100%)"
      :backgroundImage="false"
      :hideCloseBtn="createRenderType == 'mobile' ? true : false"
      :showBottomCloseBtn="createRenderType == 'mobile' ? true : false"
      @close="onCloseClick"
    >
      <div :class="`${createRenderType}-invite-popup flex-column-center`">
        <div class="title">{{ $$t("credit.inviteTitie") }}</div>
        <div class="claim" v-html="$$t('credit.claim_credits')"></div>
        <div
          :class="{
            step: true,
            'flex-row-center': createRenderType == 'pc',
            'flex-column-center': createRenderType == 'mobile',
          }"
        >
          <div
            :class="{
              'step-item': true,
              'flex-row-center': createRenderType == 'mobile',
              'flex-column-center': createRenderType == 'pc',
            }"
          >
            <div class="img flex-center">
              <div class="btn flex-center">{{ $$t("credit.copy") }}</div>
              <div class="hand"></div>
            </div>
            <div class="text">
              <div>{{ $$t("credit.step1") }}</div>
              <div>{{ $$t("credit.inviteStep1") }}</div>
            </div>
          </div>
          <div
            :class="{
              'step-item': true,
              'flex-row-center': createRenderType == 'mobile',
              'flex-column-center': createRenderType == 'pc',
            }"
          >
            <div class="img flex-center">
              <div class="btn flex-center">{{ $$t("credit.sign_in") }}</div>
              <div class="mark"></div>
            </div>
            <div class="text">
              <div>{{ $$t("credit.step2") }}</div>
              <div>{{ $$t("credit.inviteStep2") }}</div>
            </div>
          </div>
          <div
            :class="{
              'step-item': true,
              'flex-row-center': createRenderType == 'mobile',
              'flex-column-center': createRenderType == 'pc',
            }"
          >
            <div class="img flex-center">
              <img class="money" src="@/assets/img/credit/money.png" alt="" />
            </div>
            <div class="text">
              <div>{{ $$t("credit.step3") }}</div>
              <div>{{ $$t("credit.inviteStep3") }}</div>
            </div>
          </div>
        </div>
        <div
          class="copy-btn flex-center pointer"
          @click="handleCopyLink"
          @mouseleave="handleMouseLeave"
          @touchend="handleMouseLeave"
        >
          <div class="tip" v-if="showTip">{{ $$t("credit.copied") }}</div>
          {{ $$t("credit.inviteLink") }}
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import config from "@/config/config";
import { copyToClipboard } from "@/utils/tools";
import { generateInviteCode } from "@/api/api/apiCredit.js";
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import Dialog from "@/components/basic/dialog/Dialog.vue";
import { $$t } from "@/i18n/i18n.js";

const createLayoutStore = useCreateLayoutStore();
const { createRenderType } = storeToRefs(createLayoutStore);
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "",
  },
  remove: {
    type: Function, //传入移除节点方法,这里是createApp中的方法
    default: null,
  },
});

const popupVisible = ref(props.visible);
const showTip = ref(false);
const link = ref("");
const btnText = ref($$t("credit.copy"));
const loading = ref(false);
const isSmall = ref(false);
let timer = null;
let btnTimer = null;

watch(
  () => popupVisible.value,
  (val) => {
    !val && props.remove();
    if (!val) {
      timer && clearTimeout(timer);
      btnTimer && clearTimeout(btnTimer);
    }
  }
);

watch(
  () => showTip.value,
  (val) => {
    if (val) {
      timer = setTimeout(() => {
        showTip.value = false;
        clearTimeout(timer);
      }, 3000);
    }
  }
);

const onCloseClick = () => {
  popupVisible.value = false;
};

const changeBtnShowText = () => {
  btnText.value = $$t("credit.copy");
  btnTimer = setTimeout(() => {
    btnText.value = $$t("credit.copied");
    clearTimeout(btnTimer);
  }, 800);
};

const handleCopyLink = () => {
  copyToClipboard(link.value).then((res) => {
    showTip.value = true;
  });
  changeBtnShowText();
  firebaseUtils.logClickEvent("invite_link_popup_copy", {});
};

const handleMouseLeave = () => {
  showTip.value = false;
};

const getCode = () => {
  loading.value = true;
  generateInviteCode({
    source: props.type,
  })
    .then((res) => {
      if (res.code == 200) {
        if (props.type == "activity_page_link") {
          link.value = `${config.baseOrigin}?invite_code=${res?.data?.invite_code}&activity=activity_page_link`;
        } else {
          link.value = `${config.baseOrigin}?invite_code=${res?.data?.invite_code}`;
        }
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  getCode();
  if (typeof window !== "undefined") {
    if (window.innerHeight <= 547) {
      isSmall.value = true
    } else {
      isSmall.value = false
    }
  }
  firebaseUtils.logViewEvent("invite_link_popup", {});
});
</script>

<style lang="scss" scoped>
.pc-invite-popup {
  padding: 0 50px 50px;
  color: #fff;
  .title {
    padding-top: 4px;
    font-size: 36px;
    font-weight: bold;
    line-height: 42px;
    text-align: center;
    word-wrap: break-word;
  }
  ::v-deep .claim {
    margin-top: 24px;
    font-size: 20px;
    span {
      font-size: 29px;
      color: #ffe95e;
      font-weight: bold;
    }
  }
  .step {
    width: 100%;
    margin-top: 34px;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    justify-content: space-between;
    align-items: flex-start;
    &-item {
      width: 184px;
      .img {
        position: relative;
        width: 160px;
        height: 160px;
        background: url("@/assets/img/credit/invite_bg.png");
        background-size: 100% 100%;
        .btn {
          background: #94adff;
          color: #000;
          font-weight: bold;
          border-radius: 60px;
          width: 106px;
          height: 42px;
          font-size: 14px;
        }
        .hand {
          position: absolute;
          bottom: 35px;
          left: 50%;
          width: 36px;
          height: 36px;
          transform: translateX(-50%);
          background: url("@/assets/img/credit/hand.png");
          background-size: 100% 100%;
        }
        .mark {
          position: absolute;
          bottom: 44px;
          left: 75px;
          width: 42px;
          height: 32px;
          background: url("@/assets/img/credit/invite_mark.png");
          background-size: 100% 100%;
        }
        .money {
          width: 160px;
          height: 160px;
          display: block;
        }
      }
      .text {
        margin-top: 16px;
        text-align: center;
        :first-child {
          font-size: 24px;
          font-weight: bold;
          line-height: 24px;
        }
        :last-child {
          margin-top: 4px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }

  .copy-btn {
    position: relative;
    width: 100%;
    margin-top: 32px;
    height: 68px;
    border-radius: 60px;
    background: linear-gradient(97deg, #4fdeff 0%, #e675ff 100%);
    font-size: 20px;
    font-weight: bold;
    color: #000;
    .tip {
      position: absolute;
      top: calc(-100% + 15px);
      left: 50%;
      transform: translateX(-50%);
      padding: 10px 12px;
      background: #000;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      width: max-content;
      border-radius: 8px;
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateY(100%) translateX(-50%);
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 8px solid #000;
      }
    }
  }
}

.mobile-invite-popup {
  position: relative;
  color: #fff;
  padding: 0 24px 24px;
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    word-wrap: break-word;
  }
  ::v-deep .claim {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    span {
      font-size: 18px;
      color: #ffe95e;
      font-weight: bold;
    }
  }
  .step {
    width: 100%;
    margin-top: 18px;
    font-size: 12px;
    font-weight: bold;
    line-height: 26px;
    text-align: center;
    &-item {
      width: 100%;
      margin-bottom: 10px;
      .img {
        position: relative;
        width: 80px;
        height: 80px;
        background: url("@/assets/img/credit/invite_bg.png");
        background-size: 100% 100%;
        flex-shrink: 0;
        .btn {
          background: #94adff;
          color: #000;
          font-weight: bold;
          border-radius: 60px;
          width: 66px;
          height: 21px;
          line-height: 21px;
          font-size: 8px;
        }
        .hand {
          position: absolute;
          bottom: 20px;
          left: 50%;
          width: 16px;
          height: 16px;
          transform: translateX(-50%);
          background: url("@/assets/img/credit/hand.png");
          background-size: 100% 100%;
        }
        .mark {
          position: absolute;
          bottom: 22px;
          left: 38px;
          width: 20px;
          height: 16px;
          background: url("@/assets/img/credit/invite_mark.png");
          background-size: 100% 100%;
        }
        .money {
          width: 80px;
          height: 80px;
          display: block;
        }
      }
      .text {
        margin: 0 0 0 16px;
        text-align: left;
        :first-child {
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
        }
        :last-child {
          margin-top: 4px;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
  .copy-btn {
    position: relative;
    width: 100%;
    padding: 0 38px;
    margin-top: 10px;
    height: 60px;
    border-radius: 60px;
    background: linear-gradient(97deg, #4fdeff 0%, #e675ff 100%);
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: center;
    .tip {
      position: absolute;
      top: calc(-100% + 15px);
      left: 50%;
      transform: translateX(-50%);
      padding: 10px 12px;
      background: #000;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      width: max-content;
      border-radius: 8px;
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateY(100%) translateX(-50%);
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 8px solid #000;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .mobile-invite-popup {
    padding: 0 24px 24px;
    .title {
      font-size: 18px;
      line-height: 24px;
    }
    ::v-deep .claim {
      margin-top: 6px;
      font-size: 12px;
      text-align: center;
      span {
        font-size: 18px;
        color: #ffe95e;
        font-weight: bold;
      }
    }
    .step {
      font-size: 10px;
      &-item {
        .img {
          width: 70px;
          height: 70px;
        }
        .text {
          :first-child {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
          }
          :last-child {
            margin-top: 4px;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }
    }
    .copy-btn {
      height: 50px;
      font-size: 14px;
    }
  }
}
</style>
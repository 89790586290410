import { EventBus } from "@/utils/eventBus"

export const jsBridge = async (method, ...args) => {
  if (method === "view.login") {
    EventBus.emit("before:appLogin")
  }

  console.log("jsBridge执行", method, ...args)
  return window.flutter_inappwebview?.callHandler?.(method, ...args)
}

jsBridge.inMurekaApp = false

Object.defineProperty(jsBridge, "inMurekaApp", {
  get() {
    return window.flutter_inappwebview?.callHandler !== undefined
  },
})
